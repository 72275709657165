import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { CallForTender } from 'src/slices/callForTender';
import { ClockCircleOutlined } from '@ant-design/icons';

const useStyles = createUseStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: 'auto',
    gap: '20px',
  },
  card: {
    cursor: 'pointer',
    border: 'none',
    borderRadius: '6px',
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    width: '180px',
    padding: '20px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontFamily: 'PP Mori',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '21px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    marginTop: '12px',
  },
  dateIcon: {
    color: '#797676',
    fontSize: '24px',
  },
  dateText: {
    color: '#797676',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '12.6px',
  },
  openText: {
    color: 'white',
    backgroundColor: '#10B981',
    fontSize: '12px',
    padding: '4px 8px',
    borderRadius: '15px',
  },
  closedText: {
    color: 'white',
    backgroundColor: '#EF4444',
    fontSize: '12px',
    width: '48px',
    height: '18px',
    gap: '10px',
    borderRadius: '30px',
    padding: '4px 8px',
  },
});

interface CallForTenderCardsProps {
  callForTenders: CallForTender[];
  donorId: string;
  isActive?: boolean;
}

export function CallForTenderCards({
  callForTenders,
  donorId,
  isActive,
}: CallForTenderCardsProps) {
  const classes = useStyles();
  const history = useHistory();
  const uniqueIds = new Set<string>();
  const uniqueCallForTenders = callForTenders.filter((c) => {
    if (uniqueIds.has(c._id)) {
      return false;
    } else {
      uniqueIds.add(c._id);
      return true;
    }
  });

  const renderDate = (closingDate: Date | undefined) => {
    const newClosingDate = closingDate ? new Date(closingDate) : undefined;
    return (
      <div className={classes.dateContainer}>
        {newClosingDate && (
          <>
            <ClockCircleOutlined className={classes.dateIcon} />
            <div className={classes.dateText}>
              Expiration le{' '}
              {new Intl.DateTimeFormat('fr-FR', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
              }).format(newClosingDate)}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className={classes.cardContainer}>
      {uniqueCallForTenders.map((callForTender) => (
        <div
          key={callForTender?._id}
          className={classes.card}
          onClick={() =>
            history.push(`/donor/${donorId}/callForTender/${callForTender._id}`)
          }
        >
          <p className={classes.title}>{callForTender?.name}</p>
          <div>
            {isActive ? (
              <span className={classes.openText}>Ouvert</span>
            ) : (
              <span className={classes.closedText}>Fermé</span>
            )}
            {!isActive && renderDate(callForTender?.closingDate)}
          </div>
        </div>
      ))}
    </div>
  );
}

import React, { useEffect, useMemo } from 'react';
import { parse } from 'query-string';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { Donor as DonorType, getDonor } from '../slices/donor';
import { useSortedClassification } from 'src/hooks/useSortedClassification';
import { getFundedNgosFromDonorId } from '../slices/ngo';
import DonorPageLeftColumn from './donorPage/LeftColumn';
import DonorPageRightColumn from './donorPage/RightColumn';
import {
  getCallForTenderFromDonorId,
  invalidateCallForTenders,
} from 'src/slices/callForTender';
import { notification } from 'antd';
import {
  addDonor,
  getFinancingNeeds,
  removeDonor,
  resetFinancingNeedEvent,
} from 'src/slices/financingNeed';
import DonorFirstProject from './donorPage/DonorFirstProject';
import { useRouteMatch } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    width: '100%',
    padding: '60px 60px 30px 20px',
    background: 'transparent',
  },
});

interface Props {
  setIsNavBarHidden: (isHidden: boolean) => void;
}

export const Donor: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const match = useRouteMatch('/donor/:donorId/bypass');
  const isBypassPresent = match !== null;
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { donorId } = useParams<{ donorId: string }>();
  const { projectId } = parse(search);
  const classification = useSortedClassification();

  const { donors, user, callForTenders, ngos, financingNeed, financingNeeds } =
    useAppSelector((state) => ({
      donors: state.donor.donors,
      user: state.auth.user,
      callForTenders: state.callForTender.callForTenders.assignedToDonor,
      ngos: state.ngo.ngos,
      financingNeed: projectId
        ? state.financingNeed.financingNeeds.byId[projectId as string]
        : undefined,
      financingNeeds: state.financingNeed.financingNeeds,
    }));

  const financingNeedEvent = useAppSelector(({ financingNeed: { event } }) => ({
    event,
  })).event;

  useEffect(() => {
    dispatch(getDonor(donorId));
    dispatch(getFundedNgosFromDonorId(donorId));
  }, [donorId]);

  useEffect(() => {
    dispatch(invalidateCallForTenders());
    dispatch(getCallForTenderFromDonorId(donorId));
  }, []);

  useEffect(() => {
    if (!financingNeed) {
      dispatch(getFinancingNeeds(user?.ngoId));
    }
  }, [financingNeed, user?.ngoId]);

  useEffect(() => {
    if (financingNeedEvent) {
      financingNeedEvent.error
        ? notification.error({
            message:
              financingNeedEvent.action == 'addDonor'
                ? "Ce mécène n'a pas pu être ajouté à vos mécènes suivis"
                : "Ce mécène n'a pas pu être retiré de vos mécènes suivis",
            duration: 2,
          })
        : notification.success({
            message:
              financingNeedEvent.action == 'addDonor'
                ? 'Ce mécène a été ajouté à vos mécènes suivis'
                : 'Ce mécène a été retiré de vos mécènes suivis',
            duration: 2,
          });
      dispatch(resetFinancingNeedEvent());
    }
  }, [financingNeedEvent]);

  const displayedDonor = useMemo<DonorType>(
    () => donors.byId[donorId],
    [donorId, donors],
  );

  const onAddDonor = (donorId: string, financingNeedId: string) => {
    dispatch(
      addDonor({
        financingNeedId: financingNeedId,
        body: { donorId: donorId },
      }),
    );
  };

  const onRemoveDonor = (donorId: string, financingNeedId: string) => {
    dispatch(
      removeDonor({
        financingNeedId: financingNeedId,
        body: { donorId: donorId },
      }),
    );
  };
  if (!classification || !displayedDonor || !user) return <FullscreenSpin />;

  const renderDonor = () => {
    setIsNavBarHidden(false);
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DonorPageLeftColumn
          displayedDonor={displayedDonor}
          classification={classification}
          donorId={donorId}
          user={user}
          callForTenders={callForTenders}
          financingNeed={financingNeed}
          financingNeeds={financingNeeds.allIds.map(
            (financingNeedId) => financingNeeds.byId[financingNeedId],
          )}
          onAddDonor={onAddDonor}
          onRemoveDonor={onRemoveDonor}
        />
        <DonorPageRightColumn
          user={user}
          displayedDonor={displayedDonor}
          classification={classification}
          ngos={ngos}
        />
      </div>
    );
  };
  return (
    <div className={classes.container}>
      {Object.values(callForTenders).length > 0 ||
      isBypassPresent ||
      user?.role === 'admin' ||
      donorId !== user?.donorId ? (
        renderDonor()
      ) : (
        <>
          <DonorFirstProject
            user={user}
            displayedDonor={displayedDonor}
            setIsNavBarHidden={setIsNavBarHidden}
          />
        </>
      )}
    </div>
  );
};

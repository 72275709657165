import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Typography, Modal, Menu, Popover, Dropdown } from 'antd';
import { createUseStyles } from 'react-jss';
import '../../index.css';
import { useHistory } from 'react-router-dom';
import ReadMore from 'src/components/ReadMore';
import { Classification } from 'src/slices/classification';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { TagList } from '../../components/TagList';
import {
  FinancingNeed,
  invalidateFinancingNeeds,
  Offer,
  removeFinancingNeed,
} from 'src/slices/financingNeed';
import { theme } from '../../constants/theme';
import { useAppDispatch } from '../../hooks/store';
import { User } from '../../slices/auth';

const { Title, Text } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '50px !important',
    lineHeight: '52.5px !important',
    marginBottom: '30px',
    color: '#292929 !important',
  },
  dropdownContainer: {
    position: 'absolute',
    right: '20px',
    top: '45px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '0',
  },
  modifyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '4.5px',
    cursor: 'pointer',
  },
  modify: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '25px',
  },
  firstLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  moreOutlined: {
    fontWeight: '600',
    fontSize: '50px !important',
    cursor: 'pointer',
    '&:hover': {
      color: `${theme.palette.secondary} !important`,
    },
  },
  titleInformations: {
    fontFamily: "'Baloo 2'",
    fontWeight: '600',
    fontSize: '1.8875vw !important',
    // alignSelf: 'center',
    lineHeight: '1 !important',
    marginBottom: '4.76% !important',
  },
  fiche: {
    fontFamily: 'PP Mori',
    fontWeight: '600 !important',
    fontSize: '28px !important',
    lineHeight: '34px !important',
    letterSpacing: '-2% !important',
    marginBottom: '20px',
    color: '#5050F9 !important',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
    background: 'white',
    borderRadius: '10px',
    width: '869px',
    height: 'fit-content',
    position: 'relative',
  },
  titleAndTagsContainer: {
    marginBottom: '30px',
  },
  tagsContainer: {
    marginTop: '30px',
  },
  logoImage: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
    position: 'relative',
    top: '-1.11vh',
    right: 0,
  },
  editButton: {
    margin: '0 1.87% 0 0',
    fontSize: '0.875vw',
    height: '67.61%',
    padding: '0.25vw 0.9375vw',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
  },
  link: {
    marginRight: '2%',
    width: 'fit-content',
  },
  linkIcon: {
    color: theme.palette.primary,
    fontSize: '16px',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    height: '16px',
    '& a.ant-typography, .ant-typography a': {
      height: '16px !important',
    },
  },
  subTitle: {
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '23px',
    lineHeight: '28px',
    letterSpacing: '-2%',
    color: theme.palette.primary,
    marginBottom: '10px',
  },
});

interface Props {
  classification: Classification;
  projectId: string;
  project: FinancingNeed;
  user: User;
}

const ProjectPageLeftColumn: React.VFC<Props> = ({
  classification,
  projectId,
  project,
  user,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  if (!classification || !project) return <FullscreenSpin />;

  const { tags } = classification;

  /* Remove project */
  const handleRemoveFinancingNeed = () => {
    dispatch(removeFinancingNeed({ financingNeedId: projectId }));

    // TODO: invalidate financing needs
    dispatch(invalidateFinancingNeeds());
    history.goBack();
  };

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleRemoveFinancingNeed}
      okText={'Supprimer définitivement'}
      cancelText={'Annuler'}
      visible={showConfirmDelete}
      onCancel={() => setShowConfirmDelete(false)}
      okButtonProps={{
        danger: true,
      }}
    >
      <Text>{'Ètes-vous sûrs de vouloir supprimer ce projet ?'}</Text>
    </Modal>
  );

  const menuMoreOutlined = (
    <Menu>
      <Menu.Item
        onClick={() => {
          history.push(`/financing-need/${projectId}/edit`);
        }}
        icon={<EditOutlined style={{ color: 'blue' }} />}
      >
        {'Modifier'}
      </Menu.Item>

      {project.offers &&
      !project.offers.find((o) => o.offer !== Offer.visibility) ? (
        <Menu.Item
          onClick={() => {
            setShowConfirmDelete(true);
          }}
          icon={<DeleteOutlined style={{ color: 'red' }} />}
        >
          {'Supprimer'}
        </Menu.Item>
      ) : (
        <Menu.Item disabled icon={<DeleteOutlined style={{ color: 'red' }} />}>
          <Popover
            placement={'right'}
            content={
              <>
                Vous ne pouvez pas supprimer un projet ayant une offre payante !
              </>
            }
          >
            {'Supprimer'}
          </Popover>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <div className={classes.leftColumn}>
      <div className={classes.dropdownContainer}>
        {(project.adminNgos.findIndex((ngoId) => {
          return ngoId === user.ngoId;
        }) !== -1 ||
          user.role === 'admin') && (
          <Dropdown
            overlay={menuMoreOutlined}
            placement={'bottomCenter'}
            trigger={['click']}
          >
            <div className={classes.modifyContainer}>
              <EditOutlined size={14} color={'black'} />
              <Text className={classes.modify}>Modifier</Text>
            </div>
          </Dropdown>
        )}
      </div>
      <Title className={classes.fiche}>Projet</Title>
      <div className={classes.titleAndTagsContainer}>
        <div style={{ marginBottom: 'auto', marginTop: 'auto', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.firstLine}>
              <Title className={classes.title}>{project.name}</Title>
            </div>
            <div className={classes.iconsContainer}>
              {project.websiteUrl && (
                <LinkOutlined
                  className={classes.linkIcon}
                  onClick={() => window.open(project.websiteUrl, '_blank')}
                />
              )}
            </div>
          </div>
          {project.tags && (
            <div className={classes.tagsContainer}>
              <TagList
                elementIds={project.tags}
                collection={tags}
                color="default"
                collectionName="tags"
                maxElements={12}
              />
            </div>
          )}
        </div>
      </div>
      {project.description && (
        <>
          <Text className={classes.subTitle}>Description</Text>
          <ReadMore
            description={project.description}
            maximumNumberOfCharacters={1267}
          />
        </>
      )}
      {renderConfirmDelete()}
    </div>
  );
};

export default ProjectPageLeftColumn;

import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { getCallForTender } from 'src/slices/callForTender';
import { getClassification } from '../slices/classification';
import { getFundedNgosFromDonorTenderId } from '../slices/ngo';
import CallForTenderLeftColumn from './callForTenderPages/CallForTenderLeftColumn';
import CallForTenderRightColumn from './callForTenderPages/CallForTenderRightColumn';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    width: '100%',
    padding: '60px 60px 30px 20px',
    background: 'transparent',
  },
});
interface Props {
  setIsNavBarHidden: (isNavBarHidden: boolean) => void;
}

export const CallForTender: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const { callForTenderId } = useParams<{ callForTenderId: string }>();

  useEffect(() => {
    dispatch(getFundedNgosFromDonorTenderId(callForTenderId));
  }, []);

  const { callForTender, user, callForTenderLoading } = useAppSelector(
    ({ callForTender, auth }) => ({
      callForTender: callForTender.callForTenders.byId[callForTenderId],
      user: auth.user,
      callForTenderLoading: callForTender.loading,
    }),
  );

  const { classification } = useAppSelector(
    ({ classification: { classification } }) => ({
      classification,
    }),
  );

  useEffect(() => {
    setIsNavBarHidden(false);
  }, []);

  useEffect(() => {
    dispatch(getCallForTender(callForTenderId));
  }, []);

  useEffect(() => {
    dispatch(getClassification());
  }, []);

  if (!callForTender) return <FullscreenSpin />;

  if (!classification || !callForTender || !user || callForTenderLoading)
    return <FullscreenSpin />;

  return (
    <div className={classes.container}>
      <CallForTenderLeftColumn
        callForTenderId={callForTenderId}
        callForTender={callForTender}
        classification={classification}
      />
      <CallForTenderRightColumn
        callForTender={callForTender}
        classification={classification}
      />
    </div>
  );
};

import { HeartOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { theme } from '../../constants/theme';

const useStyles = createUseStyles({
  button: {
    width: 'fit-content',
    height: 37,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    borderRadius: 40,
    padding: '6px 20px',
    borderWidth: 0.6,
    border: 'solid',
    fontSize: 16,
    fontWeight: 600,
    fontFamily: 'PP Mori',
    lineHeight: '25px',
    cursor: 'pointer',
  },
  buttonSub: {
    borderColor: theme.palette.primary,
    color: theme.palette.primary,
  },
  buttonUnsub: {
    borderColor: theme.palette.error,
    color: 'white',
    backgroundColor: theme.palette.error,
  },
  icon: {
    margin: '0 !important',
  },
  iconSub: {
    color: theme.palette.primary,
  },
  iconUnsub: {
    color: 'white !important',
  },
  iconSubHover: {
    color: `${theme.palette.error} !important`,
  },
  iconUnsubHover: {
    color: 'black !important',
  },
});

interface Props {
  onClick: () => void;
  variant?: 'subscribe' | 'unsubscribe' | 'favorite' | 'unfavorite';
}

export default function SubButton({ onClick, variant }: Props) {
  const classes = useStyles();
  const [isHovered, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={clsx([
        classes.button,
        variant === 'favorite' || variant === 'subscribe'
          ? classes.buttonSub
          : classes.buttonUnsub,
      ])}
      onClick={onClick}
    >
      {(variant === 'favorite' || variant == 'unfavorite') && (
        <HeartOutlined
          size={16}
          className={clsx([
            classes.icon,
            variant === 'favorite' ? classes.iconSub : classes.iconUnsub,
            isHovered && variant === 'favorite' && classes.iconSubHover,
            isHovered && variant === 'unfavorite' && classes.iconUnsubHover,
          ])}
        />
      )}
      {variant === 'subscribe' && "S'abonner"}
      {variant === 'unsubscribe' && 'Se désabonner'}
      {variant === 'favorite' && 'Ajouter aux favoris'}
      {variant === 'unfavorite' && 'Retirer des favoris'}
    </div>
  );
}

import { Typography } from 'antd';
const { Title } = Typography;
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import ProjectSupportedByWithoutTitle from '../components/cards/ProjectSupportedByWithoutTitle';
import { FinancingNeed } from '../slices/financingNeed';

const useStyles = createUseStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '829px',
    height: '177px',
    gap: '10px',
  },
  card: {
    width: '157px',
    height: '139px',
    gap: '10px',
    borderRadius: '6px',
    padding: '20px 10px',
    boxShadow: '0px 1px 14px 0px #0000000D',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardTitle: {
    width: '137px',
    height: '42px',
    fontFamily: 'PP Mori',
    fontWeight: '600 !important',
    fontSize: '20px !important',
    lineHeight: '21px !important',
    letterSpacing: '0%',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
  },
});

interface Props {
  projects: FinancingNeed[];
  ngoName: string;
}

export const ProjectCards: React.VFC<Props> = ({ projects, ngoName }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.cardContainer}>
      {projects.map((project) => (
        <div
          key={project?._id}
          className={classes.card}
          onClick={() => history.push(`/projects/${project._id}`)}
        >
          <Title className={classes.cardTitle}>{project?.name}</Title>
          <ProjectSupportedByWithoutTitle ngoName={ngoName} />
        </div>
      ))}
    </div>
  );
};

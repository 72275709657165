import { Typography, Button, Input, Modal } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FullscreenSpin } from 'src/components/FullscreenSpin';
import {
  MailOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import Tag from 'antd/es/tag';
import { Donor, reportDonorProblem } from 'src/slices/donor';
import { Classification, Place } from 'src/slices/classification';
import { CharacteristicsList } from '../../components/lists/CharacteristicsList';
import { OddImages } from '../../components/OddImages';
import { PlacesModal } from '../../components/PlacesModal';
import { theme } from '../../constants/theme';
import { User } from '../../slices/auth';
import { NgoName } from '../../slices/ngo';
import { TagListForDonorAndNgoPages } from 'src/components/TagListForNgoAndDonorPages';
import { useAppDispatch } from 'src/hooks/store';
import { capitalizeWords } from '../../utils/string.utils';

const { Text } = Typography;

const { TextArea } = Input;

const useStyles = createUseStyles({
  subtitle: {
    color: theme.palette.primary,
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12.6px',
  },
  text: {
    fontSize: 'calc(5px + 0.4vw)',
    marginBottom: '0.7vw',
    marginTop: '0.2vw',
  },
  rightColumn: {
    flexGrow: 1,
    maxWidth: '230px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: '20px',
    gap: '20px',
  },
  rightColumnElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    background: 'white !important',
    borderRadius: '6px',
    '& .ant-list-grid .ant-col > .ant-list-item': {
      marginBottom: '0px',
    },
    '& .ant-row': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
  },
  link: {
    color: '#5050F9 !important',
  },
  avatar: {
    '&.ant-avatar': {
      marginRight: '-11px !important',
      backgroundColor: 'transparent',
    },
  },
  informationsIcon: {
    color: '#797676',
    fontSize: '20px',
    margin: '0 !important',
  },
  problemButton: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12.6px',
    textDecoration: 'underline solid',
    color: '#797676 !important',
    '&:hover': {
      cursor: 'pointer',
      color: '#000 !important',
    },
  },
  tag: {
    '& .ant-tag': {
      margin: '0px 6px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: '16px',
      lineHeight: '16.8px',
      fontWeight: '400',
      fontFamily: 'PP Mori',
    },
  },
  descriptionText: {
    border: 'none',
    cursor: 'default',
    fontSize: '16px !important',
    fontFamily: 'PP Mori',
    fontWeight: '400',
    lineHeight: '16.8px',
  },
  ngos: {
    wordBreak: 'break-all',
    lineHeight: 1.5,
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    '& .ant-tag': {
      margin: '0px 2px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
    },
    fontSize: 'calc(5px + 0.4vw)',
    textDecoration: 'underline',
  },
  ngosUnclickable: {
    wordBreak: 'break-all',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    '& .ant-tag': {
      margin: '0px 2px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
    },
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '14.7px',
    letterSpacing: '0%',
  },
  ngosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    wordBreak: 'break-all',
    lineHeight: 1.5,
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    '& .ant-tag': {
      margin: '0px 2px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-all',
    },
    fontSize: 'calc(5px + 0.4vw)',
  },
  logoImageContainer: {
    height: '106px',
    width: '230px',
    objectFit: 'contain',
    overflow: 'hidden',
  },
  logoImage: {
    height: '100%',
    width: 'auto',
  },
  donorInformations: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '24px',
  },
  contactText: {
    textAlign: 'left',
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12.5px',
    marginTop: '4px',
    color: theme.palette.grey,
  },
});

interface Props {
  displayedDonor: Donor;
  classification: Classification;
  ngos: NgoName[] | null;
  user?: User;
}

const DonorPageRightColumn: React.VFC<Props> = ({
  displayedDonor,
  classification,
  ngos,
  user,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [visible, setVisible] = React.useState(false);
  const [editedProblem, setEditedProblem] = React.useState<string>('');

  if (!classification || !displayedDonor) return <FullscreenSpin />;

  const onEditProblem = (value: string) => {
    setEditedProblem(value);
  };

  const onSubmitProblem = () => {
    if (editedProblem) {
      dispatch(
        reportDonorProblem({
          _id: displayedDonor._id,
          name: displayedDonor.name,
          problemDescription: editedProblem,
        }),
      );
      setVisible(false);
    }
    setEditedProblem('');
  };

  const [showPlacesModal, setShowPlacesModal] = useState(false);

  const {
    activityDomains,
    // activityZones,
    sustainableDevelopmentGoals,
    statuses,
    targetPersons,
    donationTypes,
    targetPopulations,
    places,
  } = classification;

  const [countries, setCountries] = useState<Place[]>([]);
  const [france, setFrance] = useState<Place[]>([]);

  useEffect(() => {
    if (places) {
      setCountries(
        places
          .filter((place) => place.countries && place.countries.length)
          .sort((a, b) => a.name.localeCompare(b.name)) as Place[],
      );
      setFrance(
        places
          .filter((place) => place.departments && place.departments.length)
          .sort((a, b) =>
            a.name.includes('DROM') ||
            a.name.includes("Collectivités d'Outre-Mer")
              ? 1
              : a.name.localeCompare(b.name),
          ) as Place[],
      );
    }
  }, [places]);

  const activityDomainsFromTags = useMemo(() => {
    if (!displayedDonor?.tags?.length || !classification.tags?.length) {
      return [];
    }

    return displayedDonor.tags
      .reduce((previousValue: string[], tagId: string) => {
        const activityDomains = classification.tags.find(
          (t) => t._id === tagId,
        )?.associatedDomains;
        if (activityDomains) {
          return previousValue.concat(activityDomains);
        }
        return previousValue;
      }, [])
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index,
      );
  }, [displayedDonor.tags, classification.tags]);

  function renderFrance(depIds: string[]): string | undefined {
    if (!depIds || depIds.length === 0) return undefined;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    if (isFranceComplete) {
      return 'France';
    }
    return undefined;
  }

  function renderDepartments(depIds: string[]): string[] {
    if (!depIds || depIds.length === 0) return [];
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((department, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {department}
    //         {', '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return [];
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((currentCountry, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {currentCountry},{' '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderPlaces(placeIds: string[]): JSX.Element[] {
    if (!placeIds || placeIds.length === 0) return [];
    let results = [] as string[];
    if (renderFrance(placeIds)) {
      const isFrance = renderFrance(placeIds);
      if (isFrance) {
        results = results.concat([isFrance]);
      }
    } else {
      if (renderDepartments(placeIds)) {
        results = results.concat(renderDepartments(placeIds));
      }
    }
    if (renderCountries(placeIds)) {
      results = results.concat(renderCountries(placeIds));
    }
    return results.length > 0
      ? results.map((department, index) =>
          index !== results.length - 1 ? (
            <Tag key={index} className={classes.tag}>
              {department}
              {', '}
            </Tag>
          ) : (
            <Tag key={index} className={classes.tag}>
              {department}
            </Tag>
          ),
        )
      : [];
  }

  const displayFundedNgos = () => {
    if (displayedDonor.fundedNgos?.length === 0) {
      return false;
    }
    const map: any[] = [];
    displayedDonor.fundedNgos?.map((ngoId) => {
      const ngo = ngos?.find((ngoName) => ngoName._id === ngoId);
      ngo ?? map.push(ngo);
    });
    return map?.length !== displayedDonor.fundedNgos?.length;
  };

  const donorInformations = [
    {
      icon: <MailOutlined width={20} className={classes.informationsIcon} />,
      description: (
        <div className={classes.contactText}>{displayedDonor.contactEmail}</div>
      ),
      valid: displayedDonor.contactEmail,
    },
    {
      icon: <PhoneOutlined width={20} className={classes.informationsIcon} />,
      description: (
        <div className={classes.contactText}>{displayedDonor.contactPhone}</div>
      ),
      valid: displayedDonor.contactPhone,
    },
    {
      icon: (
        <EnvironmentOutlined width={20} className={classes.informationsIcon} />
      ),
      description: (
        <div className={classes.contactText}>
          {displayedDonor.headquarterAddress},{' '}
          {displayedDonor.headquarterZipcode} {displayedDonor.headquarterCity}
        </div>
      ),
      valid:
        displayedDonor.headquarterAddress &&
        displayedDonor.headquarterZipcode &&
        displayedDonor.headquarterCity,
    },
  ];

  const donorCharacteristics = [
    {
      title: <Text className={classes.subtitle}>Soutien orienté vers</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            displayedDonor?.targetPerson?.length === 2
              ? [displayedDonor.targetPerson[0], displayedDonor.targetPerson[1]]
              : displayedDonor?.targetPerson?.length === 1
              ? [displayedDonor.targetPerson[0]]
              : []
          }
          collection={targetPersons}
          color="default"
          collectionName="targetPersons"
        />
      ),
      valid: displayedDonor.targetPerson,
    },
    {
      title: <Text className={classes.subtitle}>Type de mécénat</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor.donationTypes ?? []}
          collection={donationTypes}
          color="default"
          collectionName="donationTypes"
        />
      ),
      valid: displayedDonor.donationTypes,
    },
    {
      title: (
        <Text className={classes.subtitle}>Zones d&apos;intervention</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor.activityZones ?? []}
          collection={classification.activityZones}
          color="default"
          collectionName="activityZones"
        />
      ),
      valid: displayedDonor.activityZones,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <>
          {renderPlaces(displayedDonor.places ?? []).slice(0, 10)}
          {renderPlaces(displayedDonor.places ?? []).length > 10 && (
            <Button
              size={'small'}
              type="link"
              onClick={() => setShowPlacesModal(true)}
            >
              Voir Tout
            </Button>
          )}
        </>
      ),
      valid: displayedDonor.places || [],
    },
    {
      title: <Text className={classes.subtitle}>Bénéficiaires cibles</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor.targetPopulations ?? []}
          collection={targetPopulations}
          color="default"
          collectionName="targetPopulations"
        />
      ),
      valid: displayedDonor.targetPopulations,
    },

    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            displayedDonor.roadAndNumber &&
            displayedDonor.city &&
            displayedDonor.zipcode
              ? [displayedDonor.roadAndNumber]
              : []
          }
          collection={
            displayedDonor.roadAndNumber &&
            displayedDonor.city &&
            displayedDonor.zipcode
              ? [
                  {
                    _id: displayedDonor.roadAndNumber,
                    name: `${displayedDonor.roadAndNumber}, ${displayedDonor.city}, ${displayedDonor.zipcode}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueCustomAddress"
        />
      ),
      valid:
        displayedDonor.roadAndNumber &&
        displayedDonor.city &&
        displayedDonor.zipcode,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            displayedDonor.uniqueZipcode ? [displayedDonor.uniqueZipcode] : []
          }
          collection={
            displayedDonor.uniqueZipcode
              ? [
                  {
                    _id: displayedDonor.uniqueZipcode,
                    name: `${displayedDonor.uniqueZipcode}, ${displayedDonor.city}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueZipcode"
        />
      ),
      valid: displayedDonor.uniqueZipcode ? [displayedDonor.uniqueZipcode] : [],
    },
  ].filter((item) => {
    if (typeof item.valid === 'string' || Array.isArray(item.valid)) {
      return item.valid.length > 0;
    }
    return false;
  });

  const donorCharacteristics2 = [
    {
      title: <Text className={classes.subtitle}>Statut</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={displayedDonor?.status ? [displayedDonor.status] : []}
          collection={statuses}
          color="default"
          collectionName="statuses"
        />
      ),
      valid: displayedDonor.status,
    },
    {
      title: <Text className={classes.subtitle}>Abritée par</Text>,
      description: (
        <Text className={classes.descriptionText}>
          {displayedDonor.hostedBy}
        </Text>
      ),
      valid: displayedDonor.hostedBy,
    },
    {
      title: <Text className={classes.subtitle}>Année de création</Text>,
      description: (
        <Text className={classes.descriptionText}>
          {displayedDonor.creationYear + ' (Année de création)'}
        </Text>
      ),
      valid: displayedDonor.creationYear,
    },
    {
      title: <Text className={classes.subtitle}>Adresse statuaire</Text>,
      description: (
        <Text className={classes.descriptionText}>
          {displayedDonor.headquarterAddress}
        </Text>
      ),
      valid: displayedDonor.creationYear,
    },
    {
      title: <Text className={classes.subtitle}>Mode opératoire</Text>,
      description:
        displayedDonor.modusOperandi &&
        displayedDonor.modusOperandi.map((modus) => (
          <div key={modus}>
            <Tag className={classes.tag}>{modus}</Tag>
          </div>
        )),
      valid:
        displayedDonor.modusOperandi && displayedDonor.modusOperandi.length > 0,
    },
  ].filter((item) => {
    if (typeof item.valid === 'string' || typeof item.valid === 'number') {
      return item.valid !== '';
    }
    if (Array.isArray(item.valid)) {
      return item.valid.length > 0;
    }
    return false;
  });

  const donorCharacteristics3 = [
    {
      title: (
        <Text className={classes.subtitle}>Domaines d&apos;intervention</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={activityDomainsFromTags}
          collection={activityDomains}
          color="default"
          collectionName="activityDomains"
        />
      ),
      valid: activityDomainsFromTags,
    },
  ].filter((item) => {
    if (Array.isArray(item.valid)) {
      return item.valid.length > 0;
    }
    return false;
  });

  const donorCharacteristics4 = [
    {
      title: <Text className={classes.subtitle}>Documents</Text>,
      description: (
        <Text className={classes.descriptionText}>
          {displayedDonor.fileUrls?.map((fileUrl, index) => {
            try {
              console.log(fileUrl);
              const [url, fileName] = fileUrl[0].split('||||$$$$%%%%&&&&');

              return (
                <div>
                  <a
                    key={url}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {fileName}
                  </a>
                  {index !== fileUrl.length ? ',' : ''}
                </div>
              );
            } catch (e) {
              return null;
            }
          })}
        </Text>
      ),
      valid: displayedDonor.fileUrls,
    },
  ].filter((item) => {
    if (Array.isArray(item.valid)) {
      return item.valid.length > 0;
    }
    return false;
  });

  const donorInformationsFiltered = donorInformations.filter(
    (item) => item.valid,
  );

  const renderReportProblem = () => (
    <Modal
      open={visible}
      title="Signalement d'une fiche mécène"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setVisible(false);
            setEditedProblem('');
          }}
        >
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={() => onSubmitProblem()}>
          Valider
        </Button>,
      ]}
    >
      <TextArea
        placeholder="Merci de préciser le sujet avec la fiche de ce mécène"
        rows={4}
        onChange={(e) => onEditProblem(e.target.value)}
        value={editedProblem}
      />
    </Modal>
  );

  return (
    <div className={classes.rightColumn}>
      <Text className={classes.problemButton} onClick={() => setVisible(true)}>
        Signaler une anomalie avec cette fiche
      </Text>
      {renderReportProblem()}
      {displayedDonor.logoUrl && (
        <div className={classes.logoImageContainer}>
          <img
            src={displayedDonor.logoUrl}
            alt="logo"
            className={classes.logoImage}
          />
        </div>
      )}
      {!!donorInformationsFiltered.length && (
        <div className={classes.rightColumnElement} style={{ gap: '5px' }}>
          <Text className={classes.subtitle} style={{ marginBottom: '4px' }}>
            {'Contact'}
          </Text>
          {donorInformationsFiltered.map((item, index) => (
            <div className={classes.donorInformations} key={index}>
              {item.icon}
              {item.description}
            </div>
          ))}
        </div>
      )}

      <CharacteristicsList characteristics={donorCharacteristics} />
      {displayFundedNgos() && (
        <div className={classes.rightColumnElement}>
          <Text className={classes.subtitle} style={{ marginBottom: '4px' }}>
            {'Organisations soutenues'}
          </Text>

          <div className={classes.ngosContainer}>
            {displayedDonor.fundedNgos?.map(
              (ngoId, index: number, arr: string | any[]) => {
                const ngo = ngos?.find((ngoName) => ngoName._id === ngoId);
                if (ngo?.name) {
                  return (
                    <div key={ngo?._id}>
                      {
                        <Tag key={ngoId}>
                          <div className={classes.ngosUnclickable}>
                            {capitalizeWords(
                              ngo?.name + (arr.length - 1 !== index ? ',' : ''),
                            )}
                          </div>
                        </Tag>
                      }
                    </div>
                  );
                }
              },
            )}
          </div>
        </div>
      )}
      {donorCharacteristics4.length > 0 && user?.role === 'admin' && (
        <CharacteristicsList characteristics={donorCharacteristics4} />
      )}
      <CharacteristicsList characteristics={donorCharacteristics2} />

      {donorCharacteristics3.length > 0 && (
        <CharacteristicsList characteristics={donorCharacteristics3} />
      )}

      <PlacesModal
        ngoPlaces={
          displayedDonor.places && displayedDonor.places.length > 0
            ? displayedDonor.places
            : []
        }
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
      />
      <OddImages
        sustainableDevelopmentGoals={sustainableDevelopmentGoals}
        oddIds={displayedDonor?.sustainableDevelopmentGoals || []}
      />
    </div>
  );
};

export default DonorPageRightColumn;

import { Button } from 'antd';
import React from 'react';
import { createUseStyles } from 'react-jss';
import '../../index.css';

import { theme } from '../../constants/theme';
import { User } from '../../slices/auth';
import { Donor } from 'src/slices/donor';
import { useHistory } from 'react-router-dom';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label,\
        .ant-input, .ant-input-number': {
      fontSize: '0.875vw',
    },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  stepContainer: {
    height: '30vw',
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  stepColLeft: {
    backgroundImage: `url("../../images/projectConfirm.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepColRight: {
    width: '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stepImage: {
    width: '100%',
  },
  stepBar: {
    height: '1vw',
    backgroundColor: theme.palette.primary,
  },
  colRightPadding: {
    marginLeft: '3rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '18vw',
    gap: '1rem',
  },
  buttonNext: {
    width: '24vw',
    height: '2.8vw',
    fontSize: '1vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  infos: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    width: '80%',
    textAlign: 'center',
    fontSize: '1.6vw',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '1.1vw',
    marginLeft: '2rem',
    marginRight: '2rem',
    textAlign: 'center',
  },
  colRighContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '4rem',
  },
});

interface Props {
  user?: User;
  displayedDonor?: Donor;
  setEditForm: (value: boolean) => void;
}

const FinancingNeedConfirmation: React.VFC<Props> = ({ setEditForm }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.container}>
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft}></div>
        <div className={classes.stepColRight}>
          <div className={classes.stepBar} style={{ width: '100%' }}></div>
          <div className={classes.colRighContent}>
            <div className={classes.infos}>
              <div className={classes.title}>
                <p>Votre nouveau projet a bien été enregistré</p>
              </div>
              <div className={classes.subtitle}>
                <p>
                  Félicitations, votre projet est désormais visible par les
                  mécènes ! Vous pouvez désormais accéder au tableau de bord ou
                  en créer un nouveau.
                </p>
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => history.push('/projects')}
              >
                Accéder au tableau de bord
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={() => setEditForm(true)}
              >
                Créer un nouveau projet
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancingNeedConfirmation;

import React from 'react';
import { createUseStyles } from 'react-jss';
import { MenuItemProps } from 'antd';
import Menu from 'antd/lib/menu';
import { useHistory } from 'react-router-dom';

const { Item } = Menu;

export interface NavItemProps extends MenuItemProps {
  navKey: string;
  route?: string;
  icon?: React.ReactNode;
  label?: React.ReactNode | string;
  isDivider?: boolean;
}

const useStyles = createUseStyles({
  navItem: {
    fontFamily: 'PP Mori',
    width: '100%',
    margin: '0 auto',
    height: '17px',
    fontSize: '14px',
    fontWeight: 400,
    '& :hover': {
      fontWeight: 600,
    },
  },
});

export const NavItem: React.VFC<NavItemProps> = ({
  navKey,
  route,
  icon,
  label,
  onClick,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Item
      key={navKey}
      icon={icon}
      className={classes.navItem}
      onClick={(e) => {
        if (route) history.push(route);
        onClick && onClick(e);
      }}
      {...props}
    >
      {label}
    </Item>
  );
};

import {
  CloseOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  Row,
  Col,
  message,
  UploadProps,
  Typography,
  Image,
} from 'antd';

import { FullscreenSpin } from '../components/FullscreenSpin';
import { DonorEditForm } from '../containers/DonorEditForm';
import { NewDonorEditForm } from '../containers/NewDonorEditForm';

import { useAppSelector, useAppDispatch } from '../hooks/store';
import { Donor, getDonor, updateDonor } from '../slices/donor';

import { Upload } from '../components/Upload';
import { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import Text from 'antd/lib/typography/Text';
import { LoggedIntercom } from '../utils/loggedIntercom';

const useStyles = createUseStyles({
  container: {
    padding: '42px',
  },
  alignRight: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  view: {
    height: '100vh',
    width: '100vw',
  },
  filesName: {
    width: '364px',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: '5px',
  },
  logo: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  files: {
    width: '364px',
    display: 'inline-block',
    verticalAlign: 'middle',
    marginBottom: '10px',
    marginTop: '5px',
  },
  filesNameContainer: {
    width: '900px',
    alignItems: 'flex',
    display: 'flex',
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  nonRelevantButton: {},
  cancelButton: {
    marginRight: '32px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
});

const { Link } = Typography;

interface Props {
  setIsNavBarHidden: (isNavBarHidden: boolean) => void;
}

export const EditDonor: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const donor = useAppSelector((state) => state.donor.donors.byId[id]);
  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));
  const [form] = Form.useForm();
  const [showNonRelevantModal, setShowMNonRelevantModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (user?.role === 'admin') {
      setIsNavBarHidden(false);
    } else {
      setIsNavBarHidden(true);
    }
  }, [user]);

  useEffect(() => {
    if (id && !donor) {
      dispatch(getDonor(id));
    }
  }, [id]);

  useEffect(() => {
    if (donor) {
      form.setFieldsValue(donor);
    }
  }, [donor]);

  if (!donor) {
    return <FullscreenSpin />;
  }

  const handleSave = async (values: any) => {
    const resultAction = await dispatch(
      updateDonor({
        updateDonorDto: {
          _id: id,
          ...values,
          fileUrls: donor?.fileUrls,
        },
      }),
    );

    if (updateDonor.rejected.match(resultAction)) {
      message.error(
        'Une erreur est survenue lors de la sauvegarde. Merci de contacter un administrateur.',
      );
      console.log(resultAction.error);
    } else {
      history.push(`/donor/${id}`);
    }
  };

  const handleSaveNonRelevant = async () => {
    let values = {};
    try {
      values = await form.validateFields();
    } catch {}

    const resultAction = await dispatch(
      updateDonor({
        updateDonorDto: {
          _id: id,
          ...values,
          publicationStatus: '1', //TODO: change with correct number
        },
      }),
    );

    if (updateDonor.rejected.match(resultAction)) {
      message.error(
        'Une erreur est survenue lors de la sauvegarde. Merci de contacter un administrateur.',
      );
      console.log(resultAction.error);
    } else {
      history.push(`/donor/${id}`);
    }
  };

  const handleUploadLogo = async (logoUrl: string, logoName: string) => {
    const resultAction = await dispatch(
      updateDonor({
        updateDonorDto: {
          _id: id,
          logoUrl: logoUrl,
          logoName: logoName,
        },
      }),
    );

    if (updateDonor.rejected.match(resultAction)) {
      message.error(
        'Une erreur est survenue lors de la sauvegarde. Merci de contacter un administrateur.',
      );
      console.log(resultAction.error);
    }
  };

  const handleUploadFiles = async (fileUrls: string[]) => {
    const resultAction = await dispatch(
      updateDonor({
        updateDonorDto: {
          _id: id,
          fileUrls: fileUrls,
        },
      }),
    );

    if (updateDonor.rejected.match(resultAction)) {
      message.error(
        'Une erreur est survenue lors de la sauvegarde. Merci de contacter un administrateur.',
      );
      console.log(resultAction.error);
    }
  };

  const onSuccessFileUpload = (info: any) => {
    console.log('upload success');
    message.success('Fichier correctement ajouté !');
    const newFileList = [...(donor?.fileUrls ?? [])];
    newFileList.push(info.file.response + '||||$$$$%%%%&&&&' + info.file.name);
    handleUploadFiles(newFileList);
  };

  const onSuccessLogoUpload = (info: any) => {
    if (info.file.type === 'image/png' || info.file.type === 'image/jpeg') {
      console.log('logo upload success');
      message.success('Image correctement ajoutée !');
      handleUploadLogo(info.file.response, info.file.name);
    } else {
      message.error('Seul les formats jpeg et png sont acceptés');
    }
  };

  const onRemoveFile = (fileToDelete: any) => {
    const newFileList =
      donor?.fileUrls?.filter((file) => file != fileToDelete) ?? [];
    handleUploadFiles(newFileList);
  };

  const onRemoveLogo = () => {
    handleUploadLogo('', '');
  };

  const onErrorUpload = () => {
    console.log('upload error');
    message.error('Erreur lors du chargement du fichier');
  };

  const uploadPropsFile: UploadProps = {
    data: {
      name: 'file.csv',
    },
    showUploadList: false,
  };

  const uploadFileProps = {
    name: 'file',
    endpoint: '/file/upload',
    onSuccess: onSuccessFileUpload,
    onError: onErrorUpload,
    data: {
      name: 'file.csv',
    },
    uploadProps: uploadPropsFile,
    text: 'Importer un fichier',
  };

  const uploadLogoProps = {
    name: 'file',
    endpoint: '/file/upload',
    onSuccess: onSuccessLogoUpload,
    onError: onErrorUpload,
    data: {
      name: 'file.csv',
    },
    uploadProps: uploadPropsFile,
    text: 'Importer un logo',
    children: <Button icon={<UploadOutlined />}>Importer un logo</Button>,
  };

  const renderFileList = () => {
    return donor?.fileUrls?.map((file, key) => {
      try {
        const array = file[0].split('||||$$$$%%%%&&&&');
        return (
          <div className={classes.filesName} key={key}>
            <Link target="_blank" href={array[0]}>
              {array[1]}
            </Link>
            <Link onClick={() => onRemoveFile(file)}>
              <DeleteOutlined className={classes.trashIcon} />
            </Link>
          </div>
        );
      } catch {
        return null;
      }
    });
  };

  const renderLogo = () => {
    return (
      donor?.logoUrl && (
        <div>
          <Row align="middle">
            <Col xs={5} />
            <Col xs={5} xxl={3}>
              <div
                style={{ display: 'flex', float: 'left', marginTop: '10px' }}
              >
                <Image
                  src={donor?.logoUrl}
                  width="90px"
                  height="90px"
                  fallback="error"
                  alt={donor?.logoName}
                />
              </div>
            </Col>
            <Col xs={14} xxl={16}>
              <Link onClick={() => onRemoveLogo()} style={{ float: 'left' }}>
                <DeleteOutlined className={classes.trashIcon} />
              </Link>
            </Col>
          </Row>
        </div>
      )
    );
  };

  const renderUploadLogo = () => {
    return (
      <div
        style={{
          marginBottom: '25px',
          display: 'flex',
          justifyContent: 'center',
          gap: '10px',
          flexDirection: 'column',
        }}
      >
        <Text
          style={{
            width: 'auto',
          }}
        >
          Logo
        </Text>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Upload {...uploadLogoProps} />
          {renderLogo()}
        </div>
      </div>
    );
  };

  const renderUploadFiles = () => {
    return (
      <>
        <Row align="middle">
          <Col xs={5}>
            <div style={{ display: 'flex' }}>
              <Text
                style={{
                  textAlign: 'right',
                  width: '100%',
                  marginRight: '8px',
                }}
              >
                Documents :
              </Text>
            </div>
          </Col>
          <Col xs={19}>
            <Upload {...uploadFileProps} />
          </Col>
        </Row>
        <Row align="middle">
          <Col span={5} />
          <Col span={19}>
            <div className={classes.files}>{renderFileList()}</div>
          </Col>
        </Row>
      </>
    );
  };

  const renderConfirmNonRelevantModal = () => (
    <Modal
      onOk={handleSaveNonRelevant}
      okText={'Oui'}
      cancelText={'Annuler'}
      visible={showNonRelevantModal}
      onCancel={() => setShowMNonRelevantModal(false)}
    >
      <Text>
        {
          'Ètes-vous sûrs que le statut de cette fondation est «Non pertinent» ?'
        }
      </Text>
    </Modal>
  );

  const renderActionButtons = () => (
    <div className={classes.buttonContainer}>
      {user?.role === 'admin' && (
        <Button
          type="primary"
          className={classes.nonRelevantButton}
          onClick={async () => {
            setShowMNonRelevantModal(true);
          }}
          danger
        >
          Non pertinent
        </Button>
      )}
      <Button
        type="dashed"
        onClick={() => history.goBack()}
        danger
        icon={<CloseOutlined />}
      >
        Annuler
      </Button>
      <Button
        type="primary"
        onClick={async () => {
          const values = await form.validateFields();

          handleSave(values);
        }}
      >
        Sauvegarder
      </Button>
    </div>
  );

  const onSubmitDonorForm = (values: any) => {
    const { fileUrls, ...rest } = values;

    return dispatch(
      updateDonor({
        updateDonorDto: {
          _id: id,
          fileUrls: fileUrls,
          ...rest,
        },
      }),
    );
  };

  async function submitForm() {
    const fieldNames = [
      'name',
      'status',
      'creationYear',
      'headquarterZipcode',
      'headquarterCity',
      'headquarterAddress',
      'description',
      'donationTypes',
      'modusOperandi',
      'tags',
      'places',
      'roadAndNumber',
      'uniqueZipcode',
      'zipcode',
      'city',
      'activityZones',
      'targetPopulations',
      'nationality',
      'targetPerson',
      'fundedNgos',
      'contactEmail',
      'contactPhone',
      'homepageUrl',
      'logoUrl',
      'linkedinUrl',
    ];

    const allFieldValues = fieldNames.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = form.getFieldValue(fieldName);
      return acc;
    }, {});

    const donorPost = await onSubmitDonorForm(allFieldValues);
    LoggedIntercom(
      user?.firstName + ' ' + user?.lastName,
      user?.email,
      user?._id,
      'EditedDonor',
    );
    window.dataLayer.push({
      event: 'donorFirstSteps_completed',
    });
    if (donorPost.payload) {
      const donorPayload = donorPost.payload as Donor;
      console.log(donorPayload);
    }
    const payload = donorPost.payload as Donor;
    history.push(`/donor/${payload._id}`);
  }

  return (
    <div className={classes.view}>
      {user?.role === 'admin' && (
        <>
          <div className={classes.container}>
            {renderActionButtons()}
            <DonorEditForm
              donor={donor}
              form={form}
              uploadLogo={renderUploadLogo()}
              uploadFiles={renderUploadFiles()}
            />
            {renderActionButtons()}
            {renderConfirmNonRelevantModal()}
          </div>
        </>
      )}
      {user?.role !== 'admin' && (
        <div className={classes.view}>
          <NewDonorEditForm form={form} submitForm={submitForm} />
        </div>
      )}
    </div>
  );
};

import { DeleteOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Modal, Popover, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory, useParams } from 'react-router-dom';
import ReadMore from 'src/components/ReadMore';
import {
  CallForTender,
  invalidateCallForTenders,
  removeCallForTender,
} from 'src/slices/callForTender';
import { getDonor } from 'src/slices/donor';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { TagList } from '../../components/TagList';
import { theme } from '../../constants/theme';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import { Classification } from '../../slices/classification';
import { Offer } from '../../slices/financingNeed';

const { Title, Text } = Typography;

const useStyles = createUseStyles({
  title: {
    width: '60%',
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '50px !important',
    lineHeight: '52.5px !important',
    marginBottom: '30px',
    color: '#292929 !important',
  },
  dropdownContainer: {
    position: 'absolute',
    right: '20px',
    top: '45px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '0',
  },
  modifyContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '4.5px',
    cursor: 'pointer',
  },
  modify: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '25px',
  },
  firstLine: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  fiche: {
    fontFamily: 'PP Mori',
    fontWeight: '600 !important',
    fontSize: '28px !important',
    lineHeight: '34px !important',
    letterSpacing: '-2% !important',
    marginBottom: '20px',
    color: '#5050F9 !important',
  },
  subTitle: {
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '23px',
    lineHeight: '28px',
    letterSpacing: '-2%',
    color: theme.palette.primary,
    marginBottom: '10px',
  },
  leftColumn: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 20px',
    background: 'white',
    borderRadius: '10px',
    width: '869px',
    height: 'fit-content',
    position: 'relative',
  },
  linkIcon: {
    color: theme.palette.primary,
    fontSize: '16px',
  },
  titleAndTagsContainer: {
    marginBottom: '30px',
  },
  tag: {
    marginTop: '30px',
    '& .ant-tag': {
      color: 'white',
      marginBottom: '0px !important',
      borderColor: theme.palette.primary,
      backgroundColor: theme.palette.primary,
      borderRadius: '30px',
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: '12px',
      lineHeight: '14px',
      maxHeight: '21px',
    },
  },
  logoImage: {
    width: '17%',
    maxHeight: 'calc(100% + 1.11vh)',
    position: 'absolute',
    top: '-1.11vh',
    right: 0,
  },
  editButton: {
    // margin: '0 1.87% 0 0',
    fontSize: '0.875vw',
    height: '67.61%',
    // width: '21.32%',
    padding: '0.25vw 0.9375vw',
  },
  deleteButton: {
    // margin: '0 0 0 1.87%',
    fontSize: '0.875vw',
    height: '67.61%',
    width: '24.32%',
    padding: '0.25vw 0.9375vw',
    color: 'red',
    textAlign: 'center',
  },
  displayLineBreak: {
    whiteSpace: 'pre-line',
    marginBottom: '3.716%',
    textJustify: 'auto',
    textAlign: 'justify',
    wordBreak: 'break-word',
    hyphens: 'auto',
    fontSize: '0.875vw',
    ['@media screen and (max-width: 1200px)']: {
      fontSize: '9px',
    },
  },
  link: {
    color: theme.palette.secondary,
    ['@media screen and (max-width: 1000px)']: {
      fontSize: '7.5px',
    },
    marginBottom: '1.716%',
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    height: '16px',
    '& a.ant-typography, .ant-typography a': {
      height: '16px !important',
    },
  },
});

interface Props {
  callForTenderId: string;
  callForTender: CallForTender;
  classification: Classification;
}

const CallForTenderLeftColumn: React.VFC<Props> = ({
  callForTenderId,
  callForTender,
  classification,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { donorId } = useParams<{ donorId: string }>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { donor, user } = useAppSelector(({ donor, auth }) => ({
    user: auth.user,
    donor: donor.donors.byId[donorId],
  }));

  // const donor = useAppSelector((state) => state.donor.donors.byId[donorId]);

  const { tags } = classification;
  if (!callForTender) return <FullscreenSpin />;

  useEffect(() => {
    dispatch(getDonor(donorId));
  }, []);

  useEffect(() => {
    console.log('callForTender', callForTender);
  }, [callForTender]);

  const renderConfirmDelete = () => (
    <Modal
      onOk={handleDeleteCallOfTender}
      okText={'Supprimer'}
      cancelText={'Annuler'}
      visible={showDeleteModal}
      onCancel={() => setShowDeleteModal(false)}
      okButtonProps={{
        danger: true,
      }}
    >
      <Text>
        {'Êtes-vous sûr de vouloir supprimer ce programme de soutien ?'}
      </Text>
    </Modal>
  );
  const handleDeleteCallOfTender = () => {
    if (callForTender?.adminDonors && callForTender.adminDonors.length > 0) {
      const donorId = callForTender?.adminDonors[0]._id || '';
      dispatch(removeCallForTender({ callForTenderId, body: { donorId } }));
      dispatch(invalidateCallForTenders());
      history.push(`donor/${donorId}`);
    }
  };
  const menuMoreOutlined = (
    <Menu>
      <>
        <Menu.Item
          onClick={() => {
            history.push(`/donor/${callForTenderId}/editTenderDonor`);
          }}
          icon={<EditOutlined style={{ color: 'blue' }} />}
        >
          {'Modifier'}
        </Menu.Item>

        {callForTender.offers &&
        !callForTender.offers.find((o) => o.offer !== Offer.visibility) ? (
          <Menu.Item
            key="delete"
            onClick={() => {
              setShowDeleteModal(true);
            }}
            icon={<DeleteOutlined style={{ color: 'red' }} />}
          >
            {'Supprimer'}
          </Menu.Item>
        ) : (
          <Menu.Item
            key="delete"
            disabled
            icon={<DeleteOutlined style={{ color: 'red' }} />}
          >
            <Popover
              placement={'right'}
              content={
                <>
                  Vous ne pouvez pas supprimer un programme de soutien ayant une
                  offre payante !
                </>
              }
            >
              {'Supprimer'}
            </Popover>
          </Menu.Item>
        )}
      </>
    </Menu>
  );

  return (
    <div className={classes.leftColumn}>
      <div className={classes.dropdownContainer}>
        {(user?.role === 'admin' || donor._id === user?.donorId) && (
          <Dropdown
            overlay={menuMoreOutlined}
            placement={'bottomCenter'}
            trigger={['click']}
          >
            <div className={classes.modifyContainer}>
              <EditOutlined size={14} color={'black'} />
              <Text className={classes.modify}>Modifier</Text>
            </div>
          </Dropdown>
        )}
      </div>
      <Title className={classes.fiche}>Programme de soutien</Title>
      <div className={classes.titleAndTagsContainer}>
        <div style={{ marginBottom: 'auto', marginTop: 'auto', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.firstLine}>
              <Title className={classes.title}>{callForTender?.name}</Title>
            </div>
            <div className={classes.iconsContainer}>
              {callForTender.url && (
                <LinkOutlined
                  className={classes.linkIcon}
                  onClick={() => window.open(callForTender.url, '_blank')}
                />
              )}
            </div>
          </div>
          {callForTender.tags && (
            <div className={classes.tag}>
              <TagList
                elementIds={callForTender.tags}
                collection={tags}
                color="default"
                collectionName="tags"
                maxElements={12}
              />
            </div>
          )}
        </div>
      </div>
      {callForTender?.description && (
        <>
          <Text className={classes.subTitle}>Description</Text>
          <ReadMore
            description={callForTender?.description}
            maximumNumberOfCharacters={1267}
          />
        </>
      )}
      {user?.role === 'admin' && callForTender?.comments && (
        <>
          <Text className={classes.subTitle}>Commentaires</Text>
          <Text className={classes.displayLineBreak}>
            {callForTender?.comments}
          </Text>
        </>
      )}
      {renderConfirmDelete()}
    </div>
  );
};

export default CallForTenderLeftColumn;

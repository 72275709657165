import CheckMarkIcon from 'src/assets/icons/tick-outline.svg';
import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Typography,
  Divider,
  Button,
  Tag,
  Image,
  message,
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { PlacesSelectorModal } from '../components/PlacesSelectorModal';
import { TagsSelectorModal } from '../components/TagsSelectorModal';
import { theme } from '../constants/theme';
import { apiRequest } from '../helpers/api';

import { useAppDispatch, useAppSelector } from '../hooks/store';
import { useSortedClassification } from '../hooks/useSortedClassification';
import { fetchCurrentUser, updateUserNgoId } from '../slices/auth';
import { getClassification } from '../slices/classification';
import { getCommunes } from '../slices/communes';
import { Ngo, saveNgo } from '../slices/ngo';

import { FullscreenSpin } from '../components/FullscreenSpin';
import { useFormRules } from '../hooks/useFormRules';
import { FormItem } from 'src/components/FormItem';
import {
  getDonor,
  getDonorsFromNgoId,
  saveContributorDonor,
  SaveContributorDonorDto,
} from 'src/slices/donor';
import { IsValidRnaNumber } from '../types/rna.types';
import { ContributorAddDonorForm } from './ContributorAddDonorForm';
import { MoreTagsModal } from 'src/components/MoreTagsModal';
import {
  FinancingNeed,
  Offer,
  saveFinancingNeed,
} from '../slices/financingNeed';
import { LoggedIntercom } from '../utils/loggedIntercom';

const { Option } = Select;
const { Paragraph, Link } = Typography;

const layout = {
  labelCol: { span: 6 },
};

const useStyles = createUseStyles({
  link: {
    '&:hover': {
      color: '#10B981 !important',
    },
  },
  checkIcon: {
    marginRight: 10,
    width: 24, // Adjust the size as needed
    height: 24, // Adjust the size as needed
  },
  crossIcon: {
    marginRight: 10,
    width: 32, // Adjust the size as needed
    height: 32, // Adjust the size as needed
  },
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    '& .ant-form-item, .ant-form-item-label > label, .ant-input, .ant-input-number':
      {
        fontSize: '0.875vw',
      },
    '& .ant-col': {
      maxWidth: '100%',
    },
  },
  stepContainer: {
    height: '75vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  stepColLeft1: {
    backgroundImage: `url("../../images/orgaStep1.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft2: {
    backgroundImage: `url("../../images/orgaStep2.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft3: {
    backgroundImage: `url("../../images/orgaStep3.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft4: {
    backgroundImage: `url("../../images/orgaStep4.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColLeft5: {
    backgroundImage: `url("../../images/orgaStep5.webp")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '40%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
  },
  stepColRight: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    right: '0',
  },
  stepImage: {
    width: '40vw',
    position: 'fixed',
  },
  stepBar: {
    height: '1vw',
    backgroundColor: theme.palette.primary,
  },
  colRightPadding: {
    marginLeft: '6rem',
  },
  stepCounter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '0.5rem',
  },
  stepNumber: {
    fontSize: '2.5vw',
  },
  stepTotal: {
    fontSize: '1.2vw',
    color: theme.palette.primary,
  },
  buttonBox: {
    position: 'fixed',
    bottom: '0',
    left: '40vw',
    width: '40%',
    paddingBottom: '2.5vw',
    backdropFilter: 'blur(6px)',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    marginLeft: '6rem',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '0.8rem',
  },
  buttonPrevious: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonNext: {
    width: 'auto',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  buttonUpload: {
    width: 'auto%',
    height: '2.2vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: theme.palette.primary,
    color: 'white',
    borderRadius: '0.5vw',
  },
  textArea: {
    height: 200,
    marginBottom: 50,
  },
  tagCities: {
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    color: 'white',
    minHeight: '1.5vw',
    border: `1px solid ${theme.palette.primary}`,
    borderRadius: '0.5vw',
    backgroundColor: theme.palette.primary,
  },
  menu: {
    width: 800,
    whiteSpace: 'normal',
    height: '60vh',
    overflow: 'auto',
  },
  menuItem: {
    position: 'relative',
    width: '100%',
    wordWrap: 'break-word',
    marginBottom: 20,
  },
  name: {
    width: '100%',
    fontWeight: 'bold',
  },
  description: {
    width: '100%',
  },
  tag: {
    minHeight: '1.65vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '0.4vw',
    fontSize: '0.8vw',
    height: '1vw',
    color: 'white',
    backgroundColor: '#5050f6',
    border: '1px solid #5050f6',
    borderRadius: '1vw',
    paddingRight: '0.5vw',
    paddingLeft: '0.5vw',
  },
  placesTag: {
    minHeight: '2.5vw',
    fontSize: '1vw',
    margin: '2px',
    padding: '2px 7px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '1vw',
    color: 'white',
    backgroundColor: theme.palette.primary,
  },
  addressTag: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    fontSize: '1vw',
    marginLeft: '1rem',
    marginTop: '0.4rem',
  },
  button: {
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  search: {
    '& .ant-input-group-addon': {
      backgroundColor: 'white',
      fontSize: '0.875vw',
      lineHeight: '1.5715',
      height: '100%',
      '& .ant-btn': {
        padding: '4px 0 !important',
        height: 'unset',
        width: '2vw',
        fontSize: '0.875vw !important',
        boxShadow: 'none',
      },
    },
    '& .ant-btn-icon-only > *': {
      fontSize: '1vw',
    },
  },
  trashIcon: {
    color: '#002766',
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
  headquarterCityFormItem: {
    '& .ant-input-affix-wrapper-disabled': {
      backgroundColor: 'white',
    },
    '& .ant-input[disabled]': {
      color: 'black',
    },
  },
  donorsFormItem: {
    marginTop: '1vh',
    '& .ant-form-item-tooltip': {
      color: '#5050F9 !important',
    },
  },
  modal: {
    '&.ant-modal': {
      maxWidth: '1000px',
      height: 900,
      zIndex: 2000,
    },
    '&.ant-modal-body': {
      height: 900,
    },
  },
  modalWrap: {
    zIndex: 2000,
  },
  customLabel: {
    position: 'relative',
    fontSize: '1vw',
    '&::after': {
      content: '"*"',
      color: '#ff4d4f',
      fontSize: '1.2vw',
      marginLeft: '0.2vw',
    },
  },
  customLabelOptional: {
    position: 'relative',
    fontSize: '1vw',
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    width: '75%',
    marginTop: '10dvh',
  },
  inputHeight: {
    minHeight: '2vw',
    '& input': {
      height: '100%',
      padding: '0.5vw 1vw',
      color: 'black',
    },
    border: 'none',
    borderBottom: '1px solid #5050f6',
    color: '#5050f6',
    '& input::placeholder': {
      color: theme.palette.primary,
    },
    '& textarea::placeholder': {
      color: theme.palette.primary,
    },
    '& .ant-select-selection-placeholder': {
      color: theme.palette.primary,
    },
    '& textarea': {
      borderColor: '#5050f6',
      borderBottom: 'none',
      color: 'black',
    },
  },
  selectTag: {
    '& .ant-select-selection-item': {
      border: 'none',
      backgroundColor: 'white',
      maxHeight: '1.65vw',
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .ant-select-selection-item-content': {
      color: 'white',
      backgroundColor: '#5050f6',
      border: '1px solid #5050f6',
      paddingRight: '0.5vw',
      paddingLeft: '0.5vw',
      borderRadius: '1vw',
    },
  },
});

interface INgoFirstStepsForm {
  form: FormInstance;
  ngoId?: string;
}

export const NgoFirstStepsForm: React.VFC<INgoFirstStepsForm> = ({
  form,
  ngoId,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const rules = useFormRules();
  const [showMoreTagsModal, setShowMoreTagsModal] = useState(false);
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const [selectedTags, setSelectedtags] = useState<string[]>([]);
  const [cityState, setCityState] = useState<string>('');
  const [citiesState, setCitiesState] = useState<string[]>([]);
  const [addDonorModalOpen, setAddDonorModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [addDonorForm] = Form.useForm<SaveContributorDonorDto>();
  const [isValidRna, setIsValidRna] = useState(false);
  const [rnaNumber, setRnaNumber] = useState('');
  const [showFinalTagsModal, setShowFinalTagsModal] = useState(false);
  const [tempFormValues, setTempFormValues] = useState<any>(null);

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  useEffect(() => {
    fetchCurrentUser();
  }, [user]);

  // Update rnaNumber state when input changes
  const handleRnaNumberChange = (event: { target: { value: any } }) => {
    const newRnaNumber = event.target.value;
    form.setFieldsValue({ rnaNumber: newRnaNumber });
    console.log('newRnaNumber', newRnaNumber);
    setRnaNumber(newRnaNumber);
  };

  useEffect(() => {
    if (rnaNumber) {
      apiRequest<IsValidRnaNumber>('GET', `/rna/isValid/${rnaNumber}`)
        .then((data) => setIsValidRna(data.isValid))
        .catch((error) => console.error('Error fetching RNA validity:', error));
    }
  }, [rnaNumber]);

  const classification = useSortedClassification();
  const { loading, userRole, donors, communes } = useAppSelector(
    ({
      rna: { loading },
      auth,
      donor: { donors },
      communes: { communes },
    }) => ({
      loading,
      userRole: auth.user?.role,
      donors,
      communes,
    }),
  );

  useEffect(() => {
    dispatch(getClassification());
  }, []);

  useEffect(() => {
    if (ngoId) {
      dispatch(getDonorsFromNgoId(ngoId));
    }
    if (communes.length === 0) {
      dispatch(getCommunes());
    }
  }, [ngoId, communes]);

  if (!classification) return <FullscreenSpin />;

  const { tags, places, activityDomains } = classification;

  function updateCitiesState(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    const cities = communes
      .filter((c) => {
        if (c.zipcode) {
          const zipcode = c.zipcode.toString();
          if (value.toString().startsWith('0')) {
            return zipcode === value.toString().slice(1);
          }
          return zipcode === value.toString();
        }
        return false;
      })
      .map((c) => c.name);
    setCitiesState(cities);
  }

  useEffect(() => {
    if (form.getFieldValue('tags')?.length > 0 && classification.tags?.length) {
      form.setFieldsValue({
        activityDomains: form
          .getFieldValue('tags')
          .reduce((previousValue: string[], tagId: string) => {
            const activityDomains = classification.tags.find(
              (t) => t._id === tagId,
            )?.associatedDomains;
            if (activityDomains) {
              return previousValue.concat(activityDomains);
            }
            return previousValue;
          }, [])
          .filter(
            (value: string, index: number, self: string[]) =>
              self.indexOf(value) === index,
          ),
      });
    } else {
      form.setFieldsValue({
        activityDomains: [],
      });
    }
  }, [form.getFieldValue('tags')]);

  const onAddDonor = async () => {
    const valuesNewDonor = await addDonorForm.validateFields();
    setAddDonorModalOpen(false);
    const resultAction = await dispatch(saveContributorDonor(valuesNewDonor));

    if (saveContributorDonor.fulfilled.match(resultAction)) {
      dispatch(getDonor(resultAction.payload._id));
      message.success('Mécène ajouté avec succès !');
    } else {
      message.error("Erreur lors de l'ajout du Mécène");
    }
  };

  const createNgoWithRna = async () => {
    if (!user) return;

    try {
      // Debug logging
      console.log('Creating NGO with RNA number:', rnaNumber);
      console.log('Selected tags:', selectedTags);

      // Get the latest tags from state or form
      const tags = selectedTags || form.getFieldValue('tags') || [];
      console.log('Checking tags before NGO creation with RNA:', tags);

      // Make sure we have at least 2 tags selected
      if (tags.length < 2) {
        message.error('Veuillez sélectionner au moins 2 mots-clés');
        // Reopen the tag selection modal
        setShowFinalTagsModal(true);
        setShowTagsForFinalStep(true);
        return;
      }

      // Use the validated tags variable
      const ngoPayload = await apiRequest<Ngo>(
        'POST',
        '/ngo/rna',
        {},
        { rnaNumber, tags },
      );

      // Log the response
      console.log('NGO created successfully:', ngoPayload);

      await dispatch(
        updateUserNgoId({
          updateUserDto: {
            ngoId: ngoPayload._id,
            hasValidSubscription: true,
          },
          id: user._id,
        }),
      );

      if (ngoPayload) {
        try {
          // Make sure we use the tags from the response, or fallback to selected tags
          const tagsToUse =
            ngoPayload.tags && ngoPayload.tags.length > 0
              ? ngoPayload.tags
              : selectedTags;

          console.log('Using tags for financing need:', tagsToUse);

          const { payload: createdProject }: { payload: unknown } =
            await dispatch(
              saveFinancingNeed({
                name: 'Mon premier projet',
                description: 'Mon premier projet',
                tags: tagsToUse,
                adminNgos: [ngoPayload?._id],
                offers: [{ offer: Offer.visibility }],
              }),
            );

          console.log('Financing need created:', createdProject);

          window.location.href = `/freemium-projects/${
            (createdProject as FinancingNeed)._id
          }/database/false?algorithm=tagsProximity&page=1`;
        } catch (error) {
          console.error('Error creating financing need:', error);
          console.log('NGO payload:', ngoPayload);
        }
      }
    } catch (error) {
      console.error('Error creating NGO with RNA:', error);
      message.error(
        "Une erreur est survenue lors de la création de l'organisation",
      );
    }
  };

  const createNgoWithoutRna = async () => {
    if (!user) return;

    try {
      // Debug logging
      console.log('Creating NGO without RNA number');
      console.log('Selected tags:', selectedTags);

      // Get the latest tags from state or form
      const tags = selectedTags || form.getFieldValue('tags') || [];
      console.log('Checking tags before NGO creation without RNA:', tags);

      // Make sure we have at least 2 tags selected
      if (tags.length < 2) {
        message.error('Veuillez sélectionner au moins 2 mots-clés');
        // Reopen the tag selection modal
        setShowFinalTagsModal(true);
        setShowTagsForFinalStep(true);
        return;
      }

      const fieldNames = [
        'rnaNumber',
        'name',
        'status',
        'creationYear',
        'headquarterZipcode',
        'headquarterCity',
        'headquarterAddress',
        'description',
        'tags',
        'places',
        'roadAndNumber',
        'uniqueZipcode',
        'zipcode',
        'city',
        'targetPopulations',
        'unverifiedDonors',
        'websiteUrl',
        'contactMail',
        'phoneNumber',
        'logoUrl',
        'linkedinUrl',
        'instagramUrl',
        'facebookUrl',
        'twitterUrl',
      ];

      const allFieldValues = fieldNames.reduce(
        (acc: any, fieldName: string) => {
          acc[fieldName] = tempFormValues[fieldName];
          return acc;
        },
        {},
      );

      // Make sure tags are set from our validated tags variable
      allFieldValues.tags = tags;

      console.log('Submitting NGO data:', allFieldValues);

      const ngoPost = await dispatch(saveNgo({ ...allFieldValues }));
      console.log('NGO creation response:', ngoPost);

      if (user) {
        LoggedIntercom(
          user?.firstName + ' ' + user?.lastName,
          user?.email,
          user?._id,
          'ngoFirstSteps-completed',
        );
      }

      window.dataLayer.push({
        event: 'ngoFirstSteps_completed',
      });

      if (ngoPost.payload) {
        const ngoPayload = ngoPost.payload as Ngo;
        try {
          if (user) {
            await dispatch(
              updateUserNgoId({
                updateUserDto: {
                  ngoId: ngoPayload._id,
                  hasValidSubscription: true,
                },
                id: user._id,
              }),
            );
          }

          // Make sure we use the tags from the response, or fallback to selected tags
          const tagsToUse =
            ngoPayload.tags && ngoPayload.tags.length > 0
              ? ngoPayload.tags
              : selectedTags;

          console.log('Using tags for financing need:', tagsToUse);

          const { payload: createdProject }: { payload: unknown } =
            await dispatch(
              saveFinancingNeed({
                name: 'Mon premier projet',
                description: 'Mon premier projet',
                logoUrl: ngoPayload.logoUrl,
                tags: tagsToUse,
                websiteUrl: ngoPayload.websiteUrl,
                adminNgos: [ngoPayload?._id],
                offers: [{ offer: Offer.visibility }],
              }),
            );

          console.log('Financing need created:', createdProject);

          window.location.href = `/freemium-projects/${
            (createdProject as FinancingNeed)._id
          }/database/false?algorithm=tagsProximity&page=1`;
        } catch (error) {
          console.error('Error creating financing need:', error);
          console.log('NGO payload:', ngoPayload);
        }
      }
    } catch (error) {
      console.error('Error creating NGO without RNA:', error);
      message.error(
        "Une erreur est survenue lors de la création de l'organisation",
      );
    }
  };

  const renderAddDonorModal = () => (
    <Modal
      className={classes.modal}
      maskStyle={{ zIndex: 1500 }}
      width={1000}
      wrapClassName={classes.modalWrap}
      title={'Ajouter un Mécène'}
      onOk={onAddDonor}
      onCancel={() => setAddDonorModalOpen(false)}
      visible={addDonorModalOpen}
      okText={'Confirmer'}
      cancelText={'Annuler'}
      confirmLoading={loading}
    >
      {<ContributorAddDonorForm form={addDonorForm} />}
    </Modal>
  );

  // Instead of a nested modal, use the TagsSelectorModal directly with our custom handlers
  const [showTagsForFinalStep, setShowTagsForFinalStep] = useState(false);

  useEffect(() => {
    console.log('showTagsForFinalStep', showTagsForFinalStep);
  }, [showTagsForFinalStep]);

  useEffect(() => {
    if (showFinalTagsModal) {
      setShowTagsForFinalStep(true);
    }
  }, [showFinalTagsModal]);

  const handleFinalTagsComplete = () => {
    // Get the latest selected tags directly from the state
    console.log('Final tags complete handler. Selected tags:', selectedTags);

    // Validate that we have at least 2 tags before proceeding
    if (!selectedTags || selectedTags.length < 2) {
      message.error('Veuillez sélectionner au moins 2 mots-clés');
      return; // Don't close the modal if validation fails
    }

    // Close the tag selection modal
    setShowTagsForFinalStep(false);
    setShowFinalTagsModal(false);

    // Handle the appropriate flow based on RNA validation
    if (isValidRna) {
      setTimeout(() => {
        createNgoWithRna();
      }, 100); // Small delay to ensure state is updated
    } else {
      setTimeout(() => {
        createNgoWithoutRna();
      }, 100); // Small delay to ensure state is updated
    }
  };

  const handleNextClick = async () => {
    if (currentStep === 1) {
      // Validate first step
      if (
        !(await validateStep1().catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        }))
      )
        return;

      if (isValidRna) {
        // If RNA is valid, open tag selection modal directly
        setShowFinalTagsModal(true);
      } else {
        // Otherwise, proceed to second step
        setCurrentStep(2);
      }
    } else if (currentStep === 2) {
      // Validate second step
      if (
        !(await validateStep2().catch((errorInfo) => {
          console.log('Validation failed:', errorInfo);
        }))
      )
        return;

      // Store form values
      const fieldNames = [
        'rnaNumber',
        'name',
        'status',
        'creationYear',
        'headquarterZipcode',
        'headquarterCity',
        'headquarterAddress',
        'description',
        'tags',
        'places',
        'roadAndNumber',
        'uniqueZipcode',
        'zipcode',
        'city',
        'targetPopulations',
        'unverifiedDonors',
        'websiteUrl',
        'contactMail',
        'phoneNumber',
        'logoUrl',
        'linkedinUrl',
        'instagramUrl',
        'facebookUrl',
        'twitterUrl',
      ];

      const allFieldValues = fieldNames.reduce(
        (acc: any, fieldName: string) => {
          acc[fieldName] = form.getFieldValue(fieldName);
          return acc;
        },
        {},
      );

      // Store form values for later use
      setTempFormValues(allFieldValues);

      // Open tag selection modal
      setShowFinalTagsModal(true);
    }
  };

  const validateStep1 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields(['rnaNumber'])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep1 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft1}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '20%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <FormItem
                    label="→ Numéro RNA (Répertoire National des Associations)"
                    name="rnaNumber"
                    normalize={(value) => (value === '' ? null : value)}
                    rules={[rules.required(), rules.min(1), rules.max(14)]}
                    customLabelClassName={classes.customLabel}
                  >
                    <>
                      <Input
                        allowClear
                        placeholder="Entrez votre réponse ici"
                        className={classes.inputHeight}
                        onChange={handleRnaNumberChange}
                        value={rnaNumber}
                      />
                      <Link
                        href="https://lecompteasso.associations.gouv.fr/faq/le-rna/"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.link}
                      >
                        Je ne connais pas mon numéro RNA
                      </Link>
                    </>
                  </FormItem>
                </div>
                <div>
                  {isValidRna && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '0.8vw',
                        color: '#10B981',
                      }}
                    >
                      <Image
                        src={CheckMarkIcon}
                        preview={false}
                        className={classes.checkIcon}
                      />
                      <Paragraph
                        style={{
                          fontSize: '0.8vw',
                          color: '#10B981',
                          marginBottom: 0,
                        }}
                      >
                        Votre numéro de RNA a bien été reconnu. Vous pouvez
                        continuer votre inscription en cliquant sur suivant.
                      </Paragraph>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button type="default" className={classes.buttonPrevious}>
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={handleNextClick}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const validateStep2 = () => {
    return new Promise((resolve, reject) => {
      form
        .validateFields([
          'name',
          'status',
          'creationYear',
          'headquarterZipcode',
          'headquarterCity',
          'headquarterAddress',
        ])
        .then(() => {
          resolve(true);
        })
        .catch((errorInfo) => {
          reject(errorInfo);
        });
    });
  };

  const renderStep2 = () => {
    return (
      <div className={classes.stepContainer}>
        <div className={classes.stepColLeft2}></div>
        <div className={classes.stepColRight}>
          <div>
            <div className={classes.stepBar} style={{ width: '40%' }}></div>
            <div className={classes.colRightPadding}>
              <div className={classes.fieldsContainer}>
                <div className={classes.field}>
                  <FormItem
                    label="→ Nom de l'organisation"
                    name="name"
                    rules={[rules.required(), rules.max(100)]}
                    labelCol={{ span: 24 }}
                    customLabelClassName={classes.customLabel}
                  >
                    <Input
                      allowClear
                      placeholder="Entrez votre réponse ici"
                      className={`${classes.search} ${classes.inputHeight}`}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Statut"
                    name="status"
                    initialValue={'Entrez votre réponse ici'}
                    rules={
                      userRole !== 'admin'
                        ? [rules.required(), rules.status()]
                        : undefined
                    }
                    customLabelClassName={classes.customLabel}
                    style={{ height: '1vw' }}
                  >
                    <Select
                      allowClear
                      style={{ fontSize: '0.88vw' }}
                      className={classes.inputHeight}
                      bordered={false}
                    >
                      <Option value="Association">Association</Option>
                      <Option value="Autre">Autre</Option>
                    </Select>
                  </FormItem>
                </div>
                <div className={classes.field} style={{ marginTop: '3.5vw' }}>
                  <FormItem
                    label="→ Année de création"
                    name="creationYear"
                    normalize={(value) => (value === '' ? null : value)}
                    rules={
                      userRole !== 'admin' ? [rules.creationYear()] : undefined
                    }
                    customLabelClassName={classes.customLabelOptional}
                  >
                    <InputNumber
                      placeholder="Entrez votre réponse ici"
                      style={{ width: '100%' }}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  <FormItem
                    label="→ Code postal du siège social"
                    name="headquarterZipcode"
                    wrapperCol={{ span: 2 }}
                    rules={[
                      {
                        pattern: new RegExp(/^[0-9]{5}$/),
                        message: 'Un code postal complet est requis.',
                      },
                      {
                        required: true,
                        message: 'Un code postal valide est requis.',
                      },
                    ]}
                    customLabelClassName={classes.customLabel}
                  >
                    <Input
                      allowClear
                      placeholder={''}
                      onChange={(event) => {
                        if (event.target.value.length === 5) {
                          updateCitiesState(event);
                        }
                      }}
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
                <div className={classes.field}>
                  {citiesState.length > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flexWrap: 'wrap',
                          gap: '0.5vw',
                          marginBottom: '2dvh',
                        }}
                      >
                        {Array.from(new Set(citiesState)).map(
                          (city: string, index) => (
                            <div style={{ height: '2vw' }} key={index}>
                              <Tag
                                key={index}
                                onClick={() => {
                                  setCityState(city);
                                  form.setFieldsValue({
                                    headquarterCity: city,
                                  });
                                }}
                                className={classes.tagCities}
                                style={
                                  city === cityState
                                    ? {
                                        minHeight: '2vw',
                                        paddingRight: '1vw',
                                        paddingLeft: '1vw',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        borderRadius: '1vw',
                                        color: 'white',
                                        backgroundColor: '#3B38F8',
                                      }
                                    : {
                                        minHeight: '2vw',
                                        display: 'inline-flex',
                                        alignItems: 'center',
                                        borderRadius: '1vw',
                                        color: 'black',
                                        backgroundColor: '#e7e7e7',
                                        border: '1px solid #e7e7e7',
                                      }
                                }
                              >
                                {city}
                              </Tag>
                            </div>
                          ),
                        )}
                      </div>
                      <div>
                        <FormItem
                          label=""
                          name="headquarterCity"
                          wrapperCol={{ span: 4 }}
                          className={classes.headquarterCityFormItem}
                          rules={[
                            {
                              required: true,
                              message: 'Cliquez sur une ville proposée.',
                            },
                          ]}
                        >
                          <Input
                            allowClear
                            value={cityState}
                            disabled
                            placeholder={''}
                            className={classes.inputHeight}
                          />
                        </FormItem>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={classes.field}
                  style={{ paddingBottom: '100px' }}
                >
                  <FormItem
                    label="→ Numéro et adresse du siège social"
                    name="headquarterAddress"
                    wrapperCol={{ span: 8 }}
                    rules={[rules.max(100)]}
                    customLabelClassName={classes.customLabelOptional}
                    style={{ marginTop: '1vw' }}
                  >
                    <Input
                      allowClear
                      placeholder="Entrez votre réponse ici"
                      className={classes.inputHeight}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.buttonBox}>
            <div className={classes.buttonContainer}>
              <Button type="default" className={classes.buttonPrevious}>
                ← Précédent
              </Button>
              <Button
                type="default"
                className={classes.buttonNext}
                onClick={handleNextClick}
              >
                Suivant →
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Col span={14}>
        <Form
          {...layout}
          form={form}
          layout={'vertical'}
          initialValues={{ remember: true }}
        >
          {userRole === 'admin' && (
            <>
              <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                Champs Admin
              </Divider>

              <div className={classes.field}>
                <FormItem label="Mécènes vérifiés" name="donors">
                  {form.getFieldValue('donors')?.map((donorId: string) => (
                    <Tag key={donorId} className={classes.tag}>
                      {donors.byId[donorId]?.name}
                    </Tag>
                  ))}
                </FormItem>
              </div>
              <Divider style={{ fontSize: '1vw' }} orientation={'left'}>
                Champs Non-Admin
              </Divider>
            </>
          )}
          {currentStep === 1 && renderStep1()}
          {currentStep === 2 && renderStep2()}
        </Form>
      </Col>
      {renderAddDonorModal()}

      <TagsSelectorModal
        activityDomains={activityDomains}
        tags={tags}
        isVisible={showTagsForFinalStep && showFinalTagsModal}
        requestClose={() => {
          setShowTagsForFinalStep(false);
          setShowFinalTagsModal(false);
        }}
        value={selectedTags}
        onChange={(value) => {
          // Ensure we always have an array, even if value is undefined
          const tagsToUse = value ?? [];
          console.log('Tags selected in final step:', tagsToUse);

          // Update state and form synchronously
          setSelectedtags(tagsToUse);
          form.setFieldsValue({ tags: tagsToUse });

          // Debug
          setTimeout(() => {
            console.log('Tags after state update:', selectedTags);
          }, 0);
        }}
        tagsMaxQuantity={12}
        onOk={handleFinalTagsComplete}
        onCancel={() => {
          console.log('Final tags modal cancelled');
          setShowTagsForFinalStep(false);
          setShowFinalTagsModal(false);
        }}
      />

      <MoreTagsModal
        values={form.getFieldValue('tags')}
        tags={tags}
        isVisible={showMoreTagsModal}
        requestClose={() => setShowMoreTagsModal(false)}
      />

      <PlacesSelectorModal
        form={form}
        uniqueZipcode={form.getFieldValue('uniqueZipcode')}
        zipcode={form.getFieldValue('zipcode')}
        roadAndNumber={form.getFieldValue('roadAndNumber')}
        city={form.getFieldValue('city')}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
        value={form.getFieldValue('places')}
        onChange={(value) => {
          form.setFieldsValue({ places: value });
        }}
        communes={communes}
      />
    </div>
  );
};

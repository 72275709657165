import { FinancingNeed, Offer } from '../slices/financingNeed';

export function checkHasPaidOffer(financingNeed: FinancingNeed | undefined) {
  if (!financingNeed) {
    return false;
  }
  return !!financingNeed.offers.find(
    (o) =>
      (o.offer != Offer.visibility && !o.expirationDate) ||
      (o.offer != Offer.visibility &&
        o.expirationDate &&
        new Date(o.expirationDate) > new Date()),
  );
}

export function isOrganizationPremium(financingNeeds: FinancingNeed[]) {
  return financingNeeds.some((fn) => checkHasPaidOffer(fn));
}

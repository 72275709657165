import React from 'react';
import { Button } from 'antd';
import { createUseStyles } from 'react-jss';
import { theme } from '../constants/theme';

interface Props {
  setOrganisationTypeValue: (
    value: 'MECENE' | 'PROJECT_HOLDER' | undefined,
  ) => void;
}

const useStyles = createUseStyles({
  container: {
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    width: '100dvw',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100dvh',
  },
  bgImage: {
    width: '912px',
    height: '921px',
    top: '0px',
    right: '0px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signup/signupFirstStepBackground.png")`,
    position: 'absolute',
    zIndex: -1,
    overflow: 'hidden',
  },
  left: {
    marginRight: '710px',
    marginLeft: '4dvw',
  },
  sp: {
    fontWeight: '600',
    fontSize: '44px',
    lineHeight: '54px',
    color: theme.palette.primary,
  },
  par: {
    color: 'black',
    fontWeight: '400',
    fontSize: '34px',
    lineHeight: '54px',
    width: '670px',
    marginTop: '114px',
  },
  btngroup: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  button: {
    height: '3vw',
    fontSize: '0.8vw',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '4px',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
});

export const SignUpFirstStep: React.VFC<Props> = ({
  setOrganisationTypeValue,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.bgImage} />
      <div className={classes.left}>
        <div className={classes.par}>
          <span className={classes.sp}>Un seul lieu</span> pour concrétiser des
          projets, pour se trouver{' '}
          <span className={classes.sp}>autour du mécénat.</span>
        </div>
        <div style={{ width: '476px' }}>
          <div
            style={{
              fontWeight: '400',
              fontSize: '34px',
              marginTop: '153px',
              lineHeight: '54px',
            }}
          >
            Je m&apos;inscris en tant que
          </div>
          <div className={classes.btngroup}>
            <Button
              type="primary"
              className={classes.button}
              style={{
                width: '226px',
                height: '78px',
                fontSize: '18px',
                lineHeight: '18px',
              }}
              onClick={() => setOrganisationTypeValue('PROJECT_HOLDER')}
            >
              Porteur de projet
            </Button>
            <Button
              type="primary"
              className={classes.button}
              style={{
                width: '226px',
                height: '78px',
                fontSize: '18px',
                lineHeight: '18px',
              }}
              onClick={() => setOrganisationTypeValue('MECENE')}
            >
              Mécène
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

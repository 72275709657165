import React, { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { NavItem, NavItemProps } from './NavItem';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';

interface NavBarProps {
  navItems: NavItemProps[];
}

const useStyles = createUseStyles({
  label: {
    fontSize: '14px',
    position: 'relative',
  },
  menu: {
    '&.ant-menu': {
      backgroundColor: 'transparent',
    },
    '&.ant-menu-inline.ant-menu-root .ant-menu-item > *, &.ant-menu-inline.ant-menu-root .ant-menu-submenu-title > *':
      {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '17px',
      },
    '&.ant-menu-inline, &.ant-menu-vertical, &.ant-menu-vertical-left': {
      height: 'auto !important',
    },
    '& .ant-menu-item-divider': {
      backgroundColor: '#123573',
      margin: '1.25vw',
    },
    '&.ant-menu.ant-menu-light .ant-menu-item-selected': {
      backgroundColor: 'transparent',
      color: 'black',
      fontWeight: '600',
    },
    '&.ant-menu.ant-menu-light .ant-menu-item-active': {
      backgroundColor: 'transparent',
      color: 'black',
      // fontWeight: '500',
    },
    '&.ant-menu.ant-menu-light .ant-menu-item::after': {
      borderRight: 'transparent',
    },
    '& .ant-menu-item': {
      padding: '10px 37px !important',
      margin: '0px !important',
      lineHeight: '1',
      height: 'auto !important',
      display: 'flex !important',
      alignItems: 'center !important',
    },
  },
  titleLabel: {
    fontSize: '12px',
    fontFamily: 'PP Mori',
    lineHeight: '12.6px',
    color: '#A6A6A6',
    marginLeft: '37px',
    marginBottom: '10px',
    marginTop: '32px',
  },
  divider: {
    color: '#1230',
    backgroundColor: '#1230 !important',
    height: '20px',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  topList: {
    flexGrow: 1,
    height: 'auto',
  },
  bottomList: {
    height: 'auto',
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'flex-end',
  },
});

export const NavBar: React.VFC<NavBarProps> = ({ navItems }) => {
  const classes = useStyles();
  const location = useLocation();
  const [navItemsState, setNavItemsState] = React.useState<NavItemProps[]>([]);

  useEffect(() => {
    setNavItemsState(navItems);
  }, [navItems]);

  const currentNavItem = useMemo<NavItemProps | undefined>(
    () =>
      navItemsState.find(
        (navItem) =>
          location.pathname.split('/')[1] == navItem.route?.split('/')[1],
      ),
    [navItemsState, location],
  );

  useEffect(() => {
    if (currentNavItem) {
      navItemsState.find((navItem) => navItem.navKey == currentNavItem?.navKey);
      setNavItemsState((prevState) => {
        const newState = [...prevState];
        const current = newState.find(
          (navItem) => navItem.navKey == currentNavItem?.navKey,
        );
        if (current) {
          current.style = {
            color: 'blue',
          };
          newState.map((navItem) => {
            if (navItem.navKey != current.navKey) {
              navItem.style = {
                color: 'black',
              };
            }
            return navItem;
          });
        }
        return newState;
      });
    }
  }, [currentNavItem]);

  return (
    <Menu
      theme="light"
      mode="inline"
      className={classes.menu}
      selectedKeys={
        currentNavItem?.navKey ? [currentNavItem?.navKey] : undefined
      }
    >
      {navItemsState.map(({ navKey, isDivider, ...rest }, index) =>
        navKey !== 'logout' && navKey !== 'settings' ? (
          isDivider ? (
            <div key={index} className={classes.divider} />
          ) : navKey === 'title1' ? (
            <div key={index} className={classes.titleLabel}>
              Mon espace
            </div>
          ) : navKey === 'title2' ? (
            <div key={index} className={classes.titleLabel}>
              En savoir plus
            </div>
          ) : navKey === 'title3' ? (
            <div key={index} className={classes.titleLabel}>
              Les indispensables
            </div>
          ) : (
            <NavItem key={`nav-item-${index}`} navKey={navKey} {...rest} />
          )
        ) : (
          <div key={`spacer-${index}`} />
        ),
      )}
      {navItemsState.map(
        ({ navKey, ...rest }, index) =>
          (navKey === 'logout' || navKey === 'settings') && (
            <NavItem key={`footer-item-${index}`} navKey={navKey} {...rest} />
          ),
      )}
    </Menu>
  );
};

import { Popover, PopoverProps, Tag } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { theme } from '../constants/theme';
import { ClassificationElement } from '../slices/classification';

interface Props {
  elementIds: string[];
  collection: ClassificationElement[];
  collectionName: string;
  children: React.ReactNode;
  highlightedIds?: string[];
}

const useStyles = createUseStyles({
  popover: {
    margin: '0 !important',
    color: '#292929',
    fontWeight: '400',
    fontSize: '12px !important',
    lineHeight: '14px',
    height: '22px !important',
    gap: '10px',
    border: '0.5px solid #292929',
    borderRadius: '30px',
    padding: '4px 8px',
  },
  tag: {
    '&.ant-tag': {
      margin: '0px 0px 10px 0px !important',
      cursor: 'default',
      border: 'none',
      padding: '4px 8px',
      fontSize: '12px',
      lineHeight: '14px',
    },
  },
  popoverTags: {
    '& .ant-tag': {
      color: 'white',
      marginBottom: '0px !important',
      borderColor: theme.palette.primary,
      backgroundColor: theme.palette.primary,
      borderRadius: '30px',
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: '12px',
      lineHeight: '14px',
    },
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    flexDirection: 'row',
    maxWidth: '30vw',
  },
});

export const PopoverTagList: React.VFC<Props & PopoverProps> = ({
  elementIds,
  collection,
  collectionName,
  children,
  highlightedIds,
  ...rest
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const elements = useMemo<ClassificationElement[]>(
    () =>
      elementIds?.reduce(
        (elementList: ClassificationElement[], elementId: string) => {
          const element = collection.find(
            (element: ClassificationElement) => element._id === elementId,
          );
          if (element) {
            elementList.push(element);
          }
          return elementList;
        },
        [],
      ),
    [elementIds, collection],
  );

  const renderTag = (tag: ClassificationElement) => (
    <Tag
      className={classes.tag}
      key={tag._id}
      style={
        highlightedIds && highlightedIds.includes(tag._id)
          ? { backgroundColor: 'rgba(0,189,156,0.66)' }
          : undefined
      }
    >
      {t(`${collectionName}:${tag.name}`)}
    </Tag>
  );

  if (!collection) return null;

  return (
    <Popover
      showArrow={false}
      className={classes.popover}
      {...rest}
      content={
        <div className={classes.popoverTags}>
          {elements
            ?.sort((a, b) => {
              if (highlightedIds?.includes(a._id)) {
                return -1;
              }
              if (highlightedIds?.includes(b._id)) {
                return 1;
              }
              return -1;
            })
            ?.map((element) => renderTag(element))}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { Form } from 'antd';
import { FullscreenSpin } from '../components/FullscreenSpin';
import { NgoFirstStepsForm } from '../containers/NgoFirstStepsForm';
import { useAppSelector } from '../hooks/store';
import { fetchCurrentUser } from '../slices/auth';
import { Ngo } from '../slices/ngo';

const useStyles = createUseStyles({
  title: {
    fontSize: '2vw !important',
  },
  view: {
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  table: {
    maxWidth: '100%',
    margin: '1vw',
    '& .ant-table': {
      fontSize: '0.875vw',
    },
    '& .ant-table-ping-right .ant-table-cell-fix-right-first::after': {
      boxShadow: 'none',
      borderRight: '1px solid #f0f0f0',
    },
    '& .ant-table-ping-left .ant-table-cell-fix-left-last::after': {
      boxShadow: 'none',
      borderLeft: '1px solid #f0f0f0',
    },
  },
  button: {
    display: 'block',
    padding: '0.25vw 0.9375vw',
    height: '2vw',
    fontSize: '0.875vw',
  },
  column: {
    color: 'black',
    fontWeight: 'bold',
    cursor: 'pointer',
    maxWidth: '18.75vw',
  },
  cancelButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  ngoEditForm: {
    marginLeft: '40%',
  },
  select: {
    display: 'flex',
    margin: '1.25vw auto',
    width: '15.625vw',
    '& .ant-select-selector, .ant-select-selection-item, .ant-select-selection-placeholder':
      {
        height: '2vw !important',
        lineHeight: '2vw !important',
      },
    '& .ant-select-arrow': {
      fontSize: '0.75vw',
      marginTop: '-0.375vw',
      right: '0.625vw',
    },
    fontSize: '0.875vw',
  },
  cancelButton: {
    color: 'red',
    marginRight: '32px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const NgoFirstSteps: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  setIsNavBarHidden && setIsNavBarHidden(true);
  const classes = useStyles();
  const [ngoForm] = Form.useForm<Ngo>();

  const { user } = useAppSelector(({ auth: { user } }) => ({
    user,
  }));

  useEffect(() => {
    fetchCurrentUser();
  }, [user]);

  useEffect(() => {
    if (user) {
      ngoForm.setFieldsValue({ name: user.organisationName });
    }
  }, [user]);

  if (!user) {
    return <FullscreenSpin />;
  }

  return (
    <div className={classes.view}>
      <div className={classes.ngoEditForm}>
        <NgoFirstStepsForm form={ngoForm} />
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { Form, Input, Typography, Button, Divider } from 'antd';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import {
  incrementUserNbConnections,
  signIn,
  signInUpGoogle,
} from '../slices/auth';
import { useFormRules } from '../hooks/useFormRules';
import { FormItem } from 'src/components/FormItem';

const { Text } = Typography;

const useStyles = createUseStyles({
  container: {
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    width: '100dvw',
  },
  bgImage: {
    width: '912px',
    height: '921px',
    top: '0px',
    right: '0px',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url("/images/signup/signupFirstStepBackground.png")`,
    position: 'absolute',
    zIndex: -1,
    overflow: 'hidden',
  },
  logo: {
    alignSelf: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  signUp: {
    left: 0,
  },
  stepContainer: {
    marginLeft: '60px',
    width: '585px',
    display: 'flex',
    flexDirection: 'column',
  },
  customLabel: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: 'black',
  },
  button: {
    width: '182px',
    height: '58px',
    border: `1px solid ${theme.palette.primary}`,
    backgroundColor: 'white',
    color: theme.palette.primary,
    borderRadius: '0.5vw',
    fontSize: '16px',
    fontWeight: '600',
    '&:hover': {
      border: `1px solid ${theme.palette.primary}`,
      backgroundColor: theme.palette.primary,
      color: 'white',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
    },
  },
  inputHeight: {
    height: '40px',
    fontSize: '14px',
  },
  divider: {
    '& .ant-divider-inner-text': {
      padding: '0 10px',
      fontSize: '16px',
      marginTop: '32px !important',
    },
    '&::after, &::before': {
      borderTopColor: 'gray !important',
      marginTop: '32px !important',
      borderTopWidth: '1px !important',
    },
  },
  forgetPassword: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '16.8px',
    color: 'black',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary,
      textDecoration: 'underline',
    },
  },
});

export const SignIn: React.VFC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const rules = useFormRules();
  const history = useHistory();

  const { loading } = useAppSelector(({ auth: { loading } }) => ({
    loading,
  }));

  const [error, setError] = useState<string | undefined>(undefined);

  const responseGoogle = async (response: any) => {
    if (error) {
      setError(undefined);
    }

    if (response.credential) {
      const resultAction = await dispatch(
        signInUpGoogle({ googleAccessToken: response.credential }),
      );
      if (signInUpGoogle.rejected.match(resultAction)) {
        setError(resultAction.error.message);
      } else {
        const nbConnections = await dispatch(
          incrementUserNbConnections({ _id: resultAction.payload._id }),
        );
        console.log(nbConnections);
      }
    }
  };

  const onFinish = async (values: any) => {
    if (error) {
      setError(undefined);
    }

    const resultAction = await dispatch(
      signIn({ email: values.email, password: values.password }),
    );

    if (signIn.rejected.match(resultAction)) {
      setError(resultAction.error.message);
    } else {
      const nbConnections = await dispatch(
        incrementUserNbConnections({ _id: resultAction.payload._id }),
      );
      console.log(nbConnections);
      //window.location.reload();
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.bgImage} />
      <Form onFinish={onFinish}>
        <div className={classes.stepContainer}>
          <Text
            style={{
              fontWeight: '600',
              fontSize: '28px',
              lineHeight: '29.4px',
              marginTop: '111px',
              marginBottom: '40px',
            }}
          >
            Bienvenue !
          </Text>
          <FormItem>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                responseGoogle(credentialResponse);
                console.log(credentialResponse);
              }}
              onError={() => {
                responseGoogle({ error: 'error' });
                console.log('Login Failed');
              }}
              text="signin_with"
              locale="fr"
              size="large"
              width={'16vw'}
            />
          </FormItem>
          <Divider className={classes.divider}>
            <Text
              style={{
                color: 'gray',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            >
              ou
            </Text>
          </Divider>
          <Text className={classes.customLabel}>Adresse e-mail</Text>
          <FormItem
            name="email"
            rules={[rules.required(), rules.email()]}
            customLabelClassName={classes.customLabel}
          >
            <Input
              type="email"
              placeholder={'Saisissez votre adresse e-mail'}
              className={classes.inputHeight}
            />
          </FormItem>
          <Text className={classes.customLabel}>Mot de passe</Text>
          <FormItem
            name="password"
            rules={[rules.required()]}
            customLabelClassName={classes.customLabel}
          >
            <Input
              type="password"
              placeholder={'Entrez votre mot de passe'}
              className={classes.inputHeight}
            />
          </FormItem>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '40px',
              marginTop: '16px',
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className={classes.button}
            >
              Se connecter
            </Button>
            <Link
              to="/lostPassword"
              style={{}}
              className={classes.forgetPassword}
            >
              Mot de passe oublié ?
            </Link>
          </div>
          <Text type="danger">
            {!!error &&
              t(`pages.SignIn.errors.${error}`, t('common.errorGeneric'))}
          </Text>
          <Text
            style={{
              marginTop: '136px',
              fontWeight: '600',
              fontSize: '24px',
              lineHeight: '25.2px',
            }}
          >
            Vous n’avez pas encore de compte ?
          </Text>
          <Button
            style={{ marginTop: '20px' }}
            onClick={() => {
              history.push(`/signup`);
            }}
            className={classes.button}
            type="primary"
          >
            {'Inscription'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

import { BellOutlined } from '@ant-design/icons';
import { Button, Empty, Modal, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { DonorCard } from '../components/cards/donorCard';
import { theme } from '../constants/theme';
import { useAppDispatch, useAppSelector } from '../hooks/store';
import { Donor } from '../slices/donor';
import { getFinancingNeeds } from '../slices/financingNeed';
import { getFollowedDonors, getNgo, removeFollowedDonor } from '../slices/ngo';
import { isOrganizationPremium } from '../utils/projects';
const { Text } = Typography;
import { Image } from 'antd';

const useStyles = createUseStyles({
  container: {
    backgroundImage: `url("../../images/dashboardLogo.png")`,
    backgroundPosition: 'top right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  fondations: {
    paddingLeft: '64px',
    paddingTop: '4.59%',
    fontWeight: '600',
    fontFamily: 'PP Mori',
    fontSize: '2.125vw',
    lineHeight: '1',
  },
  subTitle: {
    paddingLeft: '64px',
    paddingTop: '30px',
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '23px',
    lineHeight: '28px',
    letterSpacing: '-2%',
    color: '#000',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerCards: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    margin: '32px 64px',
    paddingBottom: '4dvh',
  },
  flexWrapper: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    gap: '20px',
    rowGap: '0px',
  },
  flexItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  pagination: {
    paddingBottom: '4dvh',
  },
  link: {
    marginRight: '0.90vw',
    width: 'fit-content',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  linkIcon: {
    color: theme.palette.primary,
    fontSize: '16px',
    position: 'absolute',
    top: '154px',
    left: '14px',
    transform: 'translateX(-50%)',
    '&:hover': {
      color: 'black',
    },
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  modalTitle: {
    fontSize: '18px',
    lineHeight: '22px',
    color: '#000',
    fontWeight: '600',
    width: '646px',
    textAlign: 'center',
  },
  modalSubtitle: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000',
    fontWeight: '400',
    textAlign: 'center',
  },
  modalButtons: {
    display: 'flex',
    flexDirection: 'column',
    height: '92px',
    justifyContent: 'space-between',
  },
  buttonConfirm: {
    backgroundColor: theme.palette.primary,
    borderRadius: '6px',
    height: '40px',
    border: 'none',
  },
  buttonCancel: {
    backgroundColor: '#F3F6FD',
    margin: '0 !important',
    height: '40px',
    border: 'none',
    color: 'black',
    borderRadius: '6px',
  },
  modal: {
    // center the modal
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0',
    // no scroll bar
    height: '65dvh',
    '& .ant-modal-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    maxWidth: '400px',
    '& .ant-modal-content': {
      borderRadius: '6px',
    },
    '& .ant-modal-body': {
      display: 'none !important',
    },
    '& .ant-modal-footer': {
      borderTop: 'none',
      paddingBottom: '24px',
    },
    '& .ant-modal-header': {
      borderBottom: 'none',
    },
  },
  modalTag: {
    color: theme.palette.primary,
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '600',
    padding: '6px 20px',
    backgroundColor: '#E8EDFC',
    display: 'inline-block',
    textAlign: 'center',
  },
});

export const FollowedDonors = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [donorId, setDonorId] = useState<string>('');

  // get user from store
  const { user, followedDonors, followedDonorsLoading, ngo, financingNeeds } =
    useAppSelector(
      ({
        auth: { user },
        ngo: { ngo, followedDonors, followedDonorsLoading },
        financingNeed: { financingNeeds },
      }) => ({
        financingNeeds,
        user,
        followedDonors,
        followedDonorsLoading,
        ngo,
      }),
    );

  useEffect(() => {
    if (!user) {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getNgo(user.ngoId));
    }
  }, []);

  useEffect(() => {
    if (user?.ngoId) {
      dispatch(getFinancingNeeds(user.ngoId));
    }
  }, [user?.ngoId]);

  useEffect(() => {
    dispatch(getFollowedDonors());
  }, []);

  const onModalCancel = () => {
    setIsModalVisible(false);
  };
  const onModalOk = () => {
    dispatch(removeFollowedDonor({ donorId: donorId }));
    setIsModalVisible(false);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Text
          className={classes.fondations}
          style={{ color: theme.palette.primary }}
        >
          Abonnements
        </Text>
        <Text className={classes.subTitle}>
          {'Cliquez sur « S’abonner » sur la fiche d’un mécène pour être notifié par mail de ses programmes de' +
            ' soutien.'}
        </Text>
        <Text className={classes.subTitle}>Vos abonnements :</Text>
      </div>
      {followedDonorsLoading === 'succeeded' && followedDonors.length === 0 && (
        <Empty
          description={`Vous n'avez pas encore d'abonnements`}
          style={{ maxWidth: '500px', marginTop: '50px' }}
        />
      )}
      {followedDonorsLoading === 'succeeded' && (
        <div className={classes.containerCards}>
          <div className={classes.flexWrapper}>
            {followedDonors.map((donor: Donor) => (
              <div key={donor._id} className={classes.flexItem}>
                <DonorCard key={donor._id} donor={donor} />
                {ngo &&
                  ngo.followedDonors &&
                  ngo?.followedDonors.includes(donor._id) && (
                    <>
                      {
                        <span
                          className={classes.link}
                          onClick={() => {
                            setDonorId(donor._id);
                            setIsModalVisible(true);
                          }}
                        >
                          <BellOutlined className={classes.linkIcon} />
                        </span>
                      }
                    </>
                  )}
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal
        open={isModalVisible}
        onCancel={onModalCancel}
        onOk={onModalOk}
        okButtonProps={{ danger: true }}
        okText={'Se désabonner'}
        cancelText={'Annuler'}
      >
        <Text>
          Êtes-vous sûr de vouloir vous désabonner de{' '}
          {followedDonors.find((donor) => donor._id === donorId)?.name} ?
        </Text>
      </Modal>
      <Modal
        className={classes.modal}
        maskStyle={{
          backdropFilter: 'blur(6px)',
          backgroundColor: '#0000000F',
        }}
        width={600}
        title={
          <div className={classes.modalHeader}>
            <Text className={classes.modalTag}>Nouveauté</Text>
            <Text className={classes.modalTitle}>Veille Sonar</Text>
            <Text className={classes.modalSubtitle}>
              Cette fonctionnalité est au coeur de notre outil !
            </Text>
            <Image
              width={500}
              src={'/images/visuelPourModaleFonctionnaliteAbonnement.png'}
              preview={false}
            />
            <Text
              className={classes.modalSubtitle}
              style={{ color: theme.palette.primary, fontWeight: '600' }}
            >
              Avec la veille Sonar:
            </Text>
            <Text className={classes.modalSubtitle}>
              Abonnez vous aux mécènes et recevez par mail les nouveaux
              programmes de soutien (appels à projets, prix, bourses...).
            </Text>
            <Text className={classes.modalSubtitle}>
              Vous faire gagner du temps. Encore et toujours.
            </Text>
          </div>
        }
        open={!isOrganizationPremium(Object.values(financingNeeds.byId))}
        onCancel={() => history.push('/')}
        footer={
          <div className={classes.modalButtons}>
            <Button
              key="submit"
              type={'primary'}
              onClick={() => {
                history.push('/ngo/premium');
              }}
              className={classes.buttonConfirm}
            >
              Souscrire une offre premium
            </Button>
            <Button
              key="back"
              onClick={() => history.push('/')}
              type={'primary'}
              className={classes.buttonCancel}
            >
              Annuler
            </Button>
          </div>
        }
      />
    </div>
  );
};

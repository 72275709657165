import React from 'react';
import { createUseStyles } from 'react-jss';
import supportedByIcon from '../../assets/icons/supportedBy.svg';
import { theme } from '../../constants/theme';
import { capitalizeWords } from '../../utils/string.utils';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5.5px',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12.6px',
    color: theme.palette.grey,
    textOverflow: 'hidden',
    maxHeight: '37.8px',
    textWrap: 'ellipsis !important',
    overflow: 'hidden',
  },
});

interface Props {
  ngoName: string;
}

export default function ProjectSupportedBy({ ngoName }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <img
        src={supportedByIcon}
        style={{ width: '24px', height: '24px', alignSelf: 'center' }}
        alt={'supportedBy icon'}
      />
      <div className={classes.text}>
        Projet porté par {capitalizeWords(ngoName)}
      </div>
    </div>
  );
}

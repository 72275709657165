import { Button, Input, Modal, Tag, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getUsers } from 'src/slices/auth';
import { FullscreenSpin } from '../../components/FullscreenSpin';
import { CharacteristicsList } from '../../components/lists/CharacteristicsList';
import { OddImages } from '../../components/OddImages';
import { PlacesModal } from '../../components/PlacesModal';
import { theme } from '../../constants/theme';
import { useAppDispatch, useAppSelector } from '../../hooks/store';
import {
  FacebookFilled,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons';
import { InstagramFilled } from '@ant-design/icons';
import { TwitterSquareFilled } from '@ant-design/icons';
import { Classification, Place } from '../../slices/classification';
import { Ngo, reportNGOProblem } from 'src/slices/ngo';
import { TagListForDonorAndNgoPages } from 'src/components/TagListForNgoAndDonorPages';
import { getDonor } from '../../slices/donor';

const { Text } = Typography;

const { TextArea } = Input;

const useStyles = createUseStyles({
  socialsContainer: {
    display: 'flex',
    marginLeft: 'auto',
    height: '24px',
    gap: '7px',
  },
  problemButton: {
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12.6px',
    textDecoration: 'underline solid',
    color: '#797676 !important',
    '&:hover': {
      cursor: 'pointer',
      color: '#000 !important',
    },
  },
  subtitle: {
    color: theme.palette.primary,
    fontFamily: 'PP Mori',
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '12.6px',
  },
  description: {
    fontSize: 'calc(5px + 0.4vw)',
  },
  descriptionText: {
    border: 'none',
    cursor: 'default',
    fontSize: '16px !important',
    fontFamily: 'PP Mori',
    fontWeight: '400',
    lineHeight: '16.8px',
  },
  donorName: {
    fontSize: 'calc(5px + 0.4vw)',
    lineHeight: 1.3,
  },
  rightColumn: {
    flexGrow: 1,
    maxWidth: '230px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: '20px',
    gap: '20px',
  },
  rightColumnElement: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    background: 'white !important',
    borderRadius: '6px',
    '& .ant-list-grid .ant-col > .ant-list-item': {
      marginBottom: '0px',
    },
    '& .ant-row': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
  },
  link: {
    color: '#5050F9 !important',
  },
  avatar: {
    '&.ant-avatar': {
      marginRight: '-11px !important',
      backgroundColor: 'transparent',
    },
  },
  informationsIcon: {
    color: '#797676',
    fontSize: '20px',
    margin: '0 !important',
  },
  socialsIcon: {
    color: theme.palette.primary,
    fontSize: '20px',
  },
  tag: {
    '& .ant-tag': {
      margin: '0px 6px 0px 0px',
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      padding: 0,
      wordBreak: 'break-word',
      whiteSpace: 'unset',
      fontSize: '16px',
      lineHeight: '16.8px',
      fontWeight: '400',
      fontFamily: 'PP Mori',
    },
  },
  member: {
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1000px)']: {
      fontSize: '7.5px',
    },
  },
  activityZone: {
    fontSize: '0.75vw',
    ['@media screen and (max-width: 1000px)']: {
      fontSize: '7.5px',
    },
  },
  logoImageContainer: {
    height: '106px',
    width: '230px',
    objectFit: 'contain',
    overflow: 'hidden',
  },
  logoImage: {
    height: '100%',
    width: 'auto',
  },
  ngoInformations: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '24px',
  },
  contactText: {
    textAlign: 'left',
    fontFamily: 'PP Mori',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '12.5px',
    marginTop: '4px',
    color: theme.palette.grey,
  },
});

interface Props {
  classification: Classification;
  ngo: Ngo;
}

const NgoPageRightColumn: React.VFC<Props> = ({ classification, ngo }) => {
  const dispatch = useAppDispatch();

  const [visible, setVisible] = React.useState(false);
  const [editedProblem, setEditedProblem] = React.useState<string>('');

  const classes = useStyles();
  const [showPlacesModal, setShowPlacesModal] = useState(false);
  const { donors } = useAppSelector(({ donor }) => ({
    donors: donor.donors,
  }));

  useEffect(() => {
    // iterate through unverifiedDonord and dispatch getDonor donorId
    ngo.unverifiedDonors?.forEach((donorId) => {
      dispatch(getDonor(donorId));
    });
  }, [ngo]);

  useEffect(() => {
    if (ngo?.admins) {
      dispatch(
        getUsers(/*{
          usersIds: ngo.admins,
        }*/),
      );
    }
  }, [ngo?.admins]);

  const activityDomainsFromTags = useMemo(() => {
    if (!ngo?.tags?.length || !classification.tags?.length) {
      return [];
    }

    return ngo.tags
      .reduce((previousValue: string[], tagId: string) => {
        const activityDomains = classification.tags.find(
          (t) => t._id === tagId,
        )?.associatedDomains;
        if (activityDomains) {
          return previousValue.concat(activityDomains);
        }
        return previousValue;
      }, [])
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index,
      );
  }, [ngo.tags, classification.tags]);

  if (!classification || !ngo) return <FullscreenSpin />;

  const onEditProblem = (value: string) => {
    setEditedProblem(value);
  };

  const onSubmitProblem = () => {
    if (editedProblem) {
      dispatch(
        reportNGOProblem({
          _id: ngo._id,
          name: ngo.name,
          problemDescription: editedProblem,
        }),
      );
      setVisible(false);
    }
    setEditedProblem('');
  };

  const {
    sustainableDevelopmentGoals,
    targetPopulations,
    activityDomains,
    // activityZones,
    places,
  } = classification;
  const [countries, setCountries] = useState<Place[]>([]);
  const [france, setFrance] = useState<Place[]>([]);

  useEffect(() => {
    if (places) {
      setCountries(
        places
          .filter((place) => place.countries && place.countries.length)
          .sort((a, b) => a.name.localeCompare(b.name)) as Place[],
      );
      setFrance(
        places
          .filter((place) => place.departments && place.departments.length)
          .sort((a, b) =>
            a.name.includes('DROM') ||
            a.name.includes("Collectivités d'Outre-Mer")
              ? 1
              : a.name.localeCompare(b.name),
          ) as Place[],
      );
    }
  }, [places]);

  const renderMembers = () => {
    if (ngo.personals)
      return ngo.personals.map(
        (personnel: {
          lastName: React.Key | null | undefined;
          firstName: any;
        }) => {
          return (
            <Typography
              key={personnel.lastName}
              className={classes.member}
            >{`${personnel.firstName} ${personnel.lastName}`}</Typography>
          );
        },
      );
    return [];
  };

  const handleClickSocials = (url: string | undefined) => {
    url && window.open(url);
  };

  const renderSocials = () => {
    return (
      <>
        <div className={classes.socialsContainer}>
          {ngo.linkedinUrl && (
            <LinkedinFilled
              onClick={() => handleClickSocials(ngo.linkedinUrl)}
              className={classes.socialsIcon}
            />
          )}
          {ngo.facebookUrl && (
            <FacebookFilled
              onClick={() => handleClickSocials(ngo.facebookUrl)}
              className={classes.socialsIcon}
            />
          )}
          {ngo.twitterUrl && (
            <TwitterSquareFilled
              className={classes.socialsIcon}
              onClick={() => handleClickSocials(ngo.twitterUrl)}
            />
          )}
          {ngo.instagramUrl && (
            <InstagramFilled
              className={classes.socialsIcon}
              onClick={() => handleClickSocials(ngo.instagramUrl)}
            />
          )}
        </div>
      </>
    );
  };

  function renderFrance(depIds: string[]): string | undefined {
    if (!depIds || depIds.length === 0) return undefined;
    let isFranceComplete = true;
    france.forEach((place) => {
      if (place.departments) {
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isFranceComplete = false;
          }
        });
      }
    });
    if (isFranceComplete) {
      return 'France';
    }
    return undefined;
  }

  function renderDepartments(depIds: string[]): string[] {
    if (!depIds || depIds.length === 0) return [];
    const results: string[] = [];
    france.forEach((place) => {
      let isRegionComplete = true;
      if (place.departments) {
        const departments = [] as string[];
        place.departments.forEach((department) => {
          if (!depIds.includes(department._id)) {
            isRegionComplete = false;
          } else {
            departments.push(department.name);
          }
        });
        if (!isRegionComplete) {
          departments.forEach((department) => {
            results.push(department);
          });
        }
      }
      if (isRegionComplete) {
        results.push(place.name);
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((department, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {department}
    //         {', '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderCountries(placesId: string[]) {
    if (!placesId || placesId.length === 0) return [];
    const results: string[] = [];
    countries.forEach((place) => {
      let isContinentComplete = true;
      if (place.countries) {
        const countries = [] as string[];
        place.countries.forEach((currCountry) => {
          if (!placesId.includes(currCountry._id)) {
            isContinentComplete = false;
          } else {
            countries.push(currCountry.name);
          }
        });
        if (!isContinentComplete) {
          countries.forEach((currentCountry) => {
            results.push(currentCountry);
          });
        }
      }
      if (isContinentComplete) {
        if (place.name === 'Europe') {
          results.push('Europe');
        } else {
          results.push(place.name);
        }
      }
    });
    return results.length > 0 ? results : [];
    // return results.length > 0
    //   ? results.map((currentCountry, index) => (
    //       <Tag key={index} className={classes.tag}>
    //         {currentCountry},{' '}
    //       </Tag>
    //     ))
    //   : [];
  }

  function renderPlaces(placeIds: string[]): JSX.Element[] {
    if (!placeIds || placeIds.length === 0) return [];
    let results = [] as string[];
    if (renderFrance(placeIds)) {
      const isFrance = renderFrance(placeIds);
      if (isFrance) {
        results = results.concat([isFrance]);
      }
    } else {
      if (renderDepartments(placeIds)) {
        results = results.concat(renderDepartments(placeIds));
      }
    }
    if (renderCountries(placeIds)) {
      results = results.concat(renderCountries(placeIds));
    }
    return results.length > 0
      ? results.map((department, index) =>
          index !== results.length - 1 ? (
            <Tag key={index} className={classes.tag}>
              {department}
              {', '}
            </Tag>
          ) : (
            <Tag key={index} className={classes.tag}>
              {department}
            </Tag>
          ),
        )
      : [];
  }

  const ngoCharacteristics = [
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <>
          {renderPlaces(ngo.places ?? []).slice(0, 10)}
          {renderPlaces(ngo.places ?? []).length > 10 && (
            <Button
              size={'small'}
              type="link"
              onClick={() => setShowPlacesModal(true)}
            >
              Voir Tout
            </Button>
          )}
        </>
      ),
      valid: ngo.places ? ngo.places.length > 0 : false,
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={
            ngo.roadAndNumber && ngo.city && ngo.zipcode
              ? [ngo.roadAndNumber]
              : []
          }
          collection={
            ngo.roadAndNumber && ngo.city && ngo.zipcode
              ? [
                  {
                    _id: ngo.roadAndNumber,
                    name: `${ngo.roadAndNumber}, ${ngo.city}, ${ngo.zipcode}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueCustomAddress"
        />
      ),
      valid: !!(ngo.roadAndNumber && ngo.city && ngo.zipcode),
    },
    {
      title: (
        <Text className={classes.subtitle}>{"Lieu(x) d'intervention"}</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={ngo.uniqueZipcode ? [ngo.uniqueZipcode] : []}
          collection={
            ngo.uniqueZipcode
              ? [
                  {
                    _id: ngo.uniqueZipcode,
                    name: `${ngo.uniqueZipcode}, ${ngo.city}`,
                  },
                ]
              : []
          }
          color="default"
          collectionName="uniqueZipcode"
        />
      ),
      valid: !!ngo.uniqueZipcode,
    },
    {
      title: <Text className={classes.subtitle}>Bénéficiaires cibles</Text>,
      description: (
        <TagListForDonorAndNgoPages
          elementIds={ngo.targetPopulations ?? []}
          collection={targetPopulations}
          color="default"
          collectionName="targetPopulations"
        />
      ),
      valid: ngo.targetPopulations ? ngo.targetPopulations.length > 0 : false,
    },
  ].filter((item) => item.valid);

  const ngoCharacteristics3 = [
    {
      title: <Text className={classes.subtitle}>Statut</Text>,
      description: (
        <Text className={classes.descriptionText}>{ngo.status}</Text>
      ),
      valid: ngo.status,
    },
    {
      title: <Text className={classes.subtitle}>Année de création</Text>,
      description: (
        <Text className={classes.descriptionText}>
          {' '}
          Créée en {ngo.creationYear}
        </Text>
      ),
      valid: ngo.creationYear,
    },
    {
      title: <Text className={classes.subtitle}>Adresse statuaire</Text>,
      description: (
        <Text className={classes.descriptionText}>
          {ngo.headquarterAddress}
        </Text>
      ),
      valid: ngo.creationYear,
    },
  ].filter((item) => item.valid);

  const ngoCharacteristics4 = [
    {
      title: (
        <Text className={classes.subtitle}>Domaines d&apos;intervention</Text>
      ),
      description: (
        <TagListForDonorAndNgoPages
          elementIds={activityDomainsFromTags}
          collection={activityDomains}
          color="default"
          collectionName="activityDomains"
        />
      ),
      valid: activityDomainsFromTags.length > 0,
    },
  ].filter((item) => item.valid);

  const ngoInformations = [
    {
      icon: <MailOutlined className={classes.informationsIcon} />,
      description: <div className={classes.contactText}>{ngo.contactMail}</div>,
      valid: ngo.contactMail,
    },
    {
      icon: <PhoneOutlined className={classes.informationsIcon} />,
      description: (
        <div className={classes.contactText}>
          {ngo.phoneNumber ? '+33 (0)' + ngo.phoneNumber.slice(1) : ''}
        </div>
      ),
      valid: ngo.phoneNumber,
    },
    {
      icon: <></>,
      description: <div style={{ display: 'flex' }}>{renderSocials()}</div>,
      valid: ngo.linkedinUrl || ngo.facebookUrl || ngo.twitterUrl,
    },
  ];

  const ngoInformationsFiltered = ngoInformations.filter((item) => item.valid);

  const ngoCharacteristics2 = [
    {
      title: <Text className={classes.subtitle}>{'Mécènes'}</Text>,
      description:
        ngo.unverifiedDonors &&
        ngo.unverifiedDonors.length > 0 &&
        donors &&
        donors.byId[ngo.unverifiedDonors[0]] ? (
          ngo.unverifiedDonors.map((donor, index) => {
            return (
              (donors.byId[donor] && donors.byId[donor].name && (
                <Text
                  key={index}
                  className={classes.descriptionText}
                  // onClick={() => history.push('/donor/' + donor)}
                >
                  {donors.byId[donor].name}
                  {index + 1 !== ngo.unverifiedDonors?.length ? ', ' : ''}
                </Text>
              )) ||
              []
            );
          })
        ) : (
          <Text className={classes.donorName}>Aucun mécène renseigné</Text>
        ),
      valid: ngo.unverifiedDonors ? ngo.unverifiedDonors : [],
    },
  ].filter((item) => {
    if (typeof item.valid === 'string') {
      return item.valid !== '';
    }
    if (typeof item.valid === 'number') {
      return item.valid !== 0;
    }
    if (Array.isArray(item.valid)) {
      return item.valid.length > 0;
    }
    return false;
  });

  const renderReportProblem = (
    onChange: (value: string) => void,
    onSubmit: () => void,
    value: string,
  ) => (
    <Modal
      visible={visible}
      title="Signalement d'une fiche de porteur de projet"
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      footer={[
        <Button
          key="back"
          onClick={() => {
            setVisible(false);
            setEditedProblem('');
          }}
        >
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={() => onSubmit()}>
          Valider
        </Button>,
      ]}
    >
      <TextArea
        placeholder="Merci de préciser le sujet avec la fiche de ce porteur de projet"
        rows={4}
        onChange={(e) => onChange(e.target.value)}
        value={value}
      />
    </Modal>
  );

  return (
    <div className={classes.rightColumn}>
      <Text className={classes.problemButton} onClick={() => setVisible(true)}>
        Signaler une anomalie avec cette fiche
      </Text>
      {renderReportProblem(onEditProblem, onSubmitProblem, editedProblem)}
      {ngo.logoUrl && (
        <div className={classes.logoImageContainer}>
          <img src={ngo.logoUrl} className={classes.logoImage} alt="logo" />
        </div>
      )}
      {!!ngoInformationsFiltered.length && (
        <div className={classes.rightColumnElement} style={{ gap: '5px' }}>
          <Text className={classes.subtitle} style={{ marginBottom: '4px' }}>
            {'Contact'}
          </Text>
          {ngoInformationsFiltered.map((item, index) => (
            <div className={classes.ngoInformations} key={index}>
              {item.icon}
              {item.description}
            </div>
          ))}
        </div>
      )}

      {ngoCharacteristics.length > 0 && (
        <CharacteristicsList characteristics={ngoCharacteristics} />
      )}
      {ngoCharacteristics2.length > 0 && (
        <CharacteristicsList characteristics={ngoCharacteristics2} />
      )}
      {ngoCharacteristics3.length > 0 && (
        <CharacteristicsList characteristics={ngoCharacteristics3} />
      )}
      {ngoCharacteristics4.length > 0 && (
        <CharacteristicsList characteristics={ngoCharacteristics4} />
      )}

      {ngo.personals && ngo.personals.length !== 0 && (
        <div className={classes.rightColumnElement}>
          <Text className={classes.subtitle}>
            {ngo.personals.length === 1 ? 'Membre' : 'Membres'}
          </Text>
          <Text>{ngo.personals.length === 0 ? '' : renderMembers()}</Text>
        </div>
      )}
      <PlacesModal
        ngoPlaces={ngo.places ?? []}
        places={places}
        isVisible={showPlacesModal}
        requestClose={() => setShowPlacesModal(false)}
      />
      <OddImages
        sustainableDevelopmentGoals={sustainableDevelopmentGoals}
        oddIds={ngo?.sustainableDevelopmentGoals || []}
      />
    </div>
  );
};

export default NgoPageRightColumn;

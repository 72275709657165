import React, { useEffect, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/store';
import { FinancingNeed, getFinancingNeed } from 'src/slices/financingNeed';
import { getDealFlow } from 'src/slices/dealFlow';
import { FullscreenSpin } from 'src/components/FullscreenSpin';
import { getClassification } from 'src/slices/classification';
import { getNgo } from '../slices/ngo';
import ProjectPageLeftColumn from './projectPage/ProjectPageLeftColumn';
import ProjectPageRightColumn from './projectPage/ProjectPageRightColumn';
import { useSortedClassification } from 'src/hooks/useSortedClassification';

const useStyles = createUseStyles({
  projectContainer: {
    display: 'flex',
    width: '100%',
    padding: '60px 60px 30px 20px',
    background: 'transparent',
  },
});

interface Props {
  setIsNavBarHidden?: (isHidden: boolean) => void;
}

export const Project: React.VFC<Props> = ({ setIsNavBarHidden }) => {
  const classes = useStyles();
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const classification = useSortedClassification();

  useEffect(() => {
    if (setIsNavBarHidden) {
      setIsNavBarHidden(false);
    }
  }, []);

  // const history = useHistory();
  const { financingNeeds, loading, user, ngo } = useAppSelector(
    ({
      financingNeed: { financingNeeds },
      dealFlow: { loading },
      auth: { user },
      ngo: { ngo },
    }) => ({
      financingNeeds,
      loading,
      user,
      ngo,
    }),
  );
  // const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const displayedFinancingNeed = useMemo<FinancingNeed>(
    () => financingNeeds.byId[projectId],
    [financingNeeds.byId[projectId]],
  );

  useEffect(() => {
    dispatch(getClassification());
  }, []);

  useEffect(() => {
    dispatch(getFinancingNeed(projectId));
  }, [projectId]);

  useEffect(() => {
    if (displayedFinancingNeed)
      dispatch(getNgo(displayedFinancingNeed.adminNgos[0]));
  }, [displayedFinancingNeed]);

  useEffect(() => {
    if (displayedFinancingNeed)
      dispatch(getDealFlow(displayedFinancingNeed.dealFlow));
  }, [displayedFinancingNeed]);

  if (!displayedFinancingNeed || !user || loading) return <FullscreenSpin />;
  return (
    <div className={classes.projectContainer}>
      <ProjectPageLeftColumn
        projectId={projectId}
        classification={classification}
        project={displayedFinancingNeed}
        user={user}
      />
      <ProjectPageRightColumn
        classification={classification}
        project={displayedFinancingNeed}
        ngoName={ngo?.name ?? ''}
      />
    </div>
  );
};
